import {
    createScreenShot,
    editScreenShot,
    getScreenShotData,
    getScreenShotSingleData,
    removeScreenShot,
} from "../../service/mapStateService";
import {
    SET_CATEGORIES_ARTICLE_ITEM_ID,
    SET_CATEGORIES_POPUP_SCROLL_POSITION,
    SET_CATEGORIES_SORTING,
    SET_LAMP_DATA_FILTERED_SCREEN_SHOT,
    SET_LAMP_DATA_STATE,
    SET_LAMP_HIDE_AND_SHOW_DATA_ST,
    SET_LAMP_SELECTED_ID,
    SET_LAMP_SORTED_TOOLTIP_STATE,
    SET_LAMP_SORTING,
    SET_MAP_CENTER_POSITION,
    SET_MAP_CENTER_POSITION_STATUS,
    SET_MAP_REQUIREMENT_SCREEN_SHOT,
    SET_MAP_STATE_DATA,
    SET_MAP_STATE_SINGLE_DATA,
    SET_MAP_STYLES_DARK_ID,
    SET_MAP_STYLES_ID,
    SET_MAP_STYLES_STATUS,
    SET_MOBILE_MENU_CLOSE,
    SET_OPEN_CATEGORY_ID,
    SET_PAINTER_GEOJSON_DATA_ST,
    SET_REMOVE_CARD_ID,
    SET_RULER_GEOJSON_DATA_ST,
    SET_RULER_HIDE_ALL_POPUP,
    SET_RULER_LINE_STRING_ST,
    SET_RULER_POINTS_POS_DATA_ST,
    SET_RULER_SOURCE_NAME_ST,
    SET_RULER_SOURCE_SECONDE_NAME_ST,
    SET_SCREENSHOT_BUTTON_CLICK,
    SET_SCREENSHOT_BUTTON_STATE,
    SET_SCREENSHOT_INTERMEDIATE_DATA,
    SET_SCREENSHOT_LOADER,
    SET_SCREENSHOT_SEND_DATA,
    SET_SCREEN_SHOT_LOADING,
    SET_SEARCH_TEXT,
    SET_TEST_FILTERED_CATEGORIES,
    SET_TIMELINE_CLICK_STATE,
    SET_TIMELINE_CURSOR_STATUS_ST,
    SET_TIMELINE_EVENT_DATA_ST,
    SET_TIMELINE_MOVE_COUNT_ST,
    SET_TIMELINE_MOVE_DATA_ST,
    SET_TIMELINE_MOVE_DATA_STATUS_ST,
    SET_TIMELINE_MOVE_POSITION,
    SET_TIMELINE_MOVE_STATUS,
    SET_TIMELINE_SCROLL_ZOOM,
    SET_TIMELINE_ZOOM_CHANGE,
    SET_TIMELINE_ZOOM_INDEX_ST,
    SET_TIMELINE_ZOOM_LEVEL,
    SET_TIMELINE_ZOOM_ST,
    SET_TOPIC_STATIC_TITLE_ST,
    SET_TIMELINE_HEIGHT,
    SET_TIMELINE_BLOCKS_SCROLL_POSITION,
    SET_SEARCH_CATEGORY_ID,
    SET_TIMELINE_MID_BLOCK_PARAMS
} from "../constants";
import {getArticleFromAPI, setArticles} from "./articles";
import {setFilteredCategories, setFilteredCategoriesST} from "./categories";
import {setHints} from "./hints";
import {setMapRequirement} from "./mapRequirement";
import {
    changeMapRequirements,
    changeMapRequirementsScreenShot,
} from "./mapRequirements";
import {setMenuExpend} from "./menuExpend";
import {setQuizPopup} from "./quizPopup";

import {parse, stringify} from "flatted";
import {
    setCompassCoordinates,
    setCompassNewAngle,
    setCompassRotate,
} from "./compassStateAction";
import {changeMapStyles, setMapStylesDark} from "./mapStyles";
import {
    setColorPickerShowState,
    setEraserClickState,
    setPainterStart,
    setPointerColor,
    setPointerColorData,
    setPointerSize,
    setPointerSizeData,
    setRulerClickedState,
    setStartPaintClickState,
} from "./painterStart";
import {setSearch} from "./search";
import {setTimeLineExpend} from "./timeLineExpend";
import {setMapZoom} from "./zoom";
import {setSelectedLesson} from "./handleLesson";
import {setMenu} from "./menu";
import {setMapSelectedCluster} from "./map";
import {arrayProxy} from "../../service/categories";
import {arrayProxy as LegendArrayProxy} from "../../service/legends";
import {setLamp, setLampModalState, setLampModalStateIndex} from "./lamp";
import {setFilteredLegends} from "./legends";

export const setScreenShotButtonStateST = (state) => {
    return {
        type: SET_SCREENSHOT_BUTTON_STATE,
        payload: state,
    };
};

export const setMapStateData = (data) => ({
    type: SET_MAP_STATE_DATA,
    payload: data,
});

export const setMapStateSingleData = (data) => ({
    type: SET_MAP_STATE_SINGLE_DATA,
    payload: data,
});

export const setOpenCategoryID = (id) => {
    return {
        type: SET_OPEN_CATEGORY_ID,
        payload: id,
    }
};

export const setOpenSubCategoryID = (id) => ({
    type: SET_OPEN_CATEGORY_ID,
    payload: id,
});

export const setMapRequiredScreenShot = (id) => ({
    type: SET_MAP_REQUIREMENT_SCREEN_SHOT,
    payload: id,
});

export const setCategoriesPopupScrollPosition = (pos) => ({
    type: SET_CATEGORIES_POPUP_SCROLL_POSITION,
    payload: pos,
});
export const setCategoriesClickedArticleId = (id) => ({
    type: SET_CATEGORIES_ARTICLE_ITEM_ID,
    payload: id,
});
export const setLampDataStateScreenShot = (data) => ({
    type: SET_LAMP_DATA_STATE,
    payload: data,
});

export const setLampDataFilteredScreenShot = (data) => {
    return {
        type: SET_LAMP_DATA_FILTERED_SCREEN_SHOT,
        payload: data,
    };
};

export const setLampSortedTooltipState = (data) => {
    return {
        type: SET_LAMP_SORTED_TOOLTIP_STATE,
        payload: data,
    };
};

export const setTestFilteredCategories = (data) => {
    return {
        type: SET_TEST_FILTERED_CATEGORIES,
        payload: data,
    };
};

export const setLampSelectedId = (data) => {
    return {
        type: SET_LAMP_SELECTED_ID,
        payload: data,
    };
};
export const setSearchText = (data) => {
    return {
        type: SET_SEARCH_TEXT,
        payload: data,
    };
};
export const setSearchCategoryId = (id) => {
    return {
        type: SET_SEARCH_CATEGORY_ID,
        payload: id,
    };
};
export const setMapStylesIdST = (id) => {
    return {
        type: SET_MAP_STYLES_ID,
        payload: id,
    };
};
export const setMapStylesDarkId = (id) => {
    return {
        type: SET_MAP_STYLES_DARK_ID,
        payload: id,
    };
};

export const setPainterGeoJsonDataST = (data) => {
    return {
        type: SET_PAINTER_GEOJSON_DATA_ST,
        payload: data,
    };
};

export const setRulerGeoJsonDataST = (data) => {
    return {
        type: SET_RULER_GEOJSON_DATA_ST,
        payload: data,
    };
};

export const setRulerSourceName = (data) => {
    return {
        type: SET_RULER_SOURCE_NAME_ST,
        payload: data,
    };
};

export const setRulerSourceSecondName = (data) => {
    return {
        type: SET_RULER_SOURCE_SECONDE_NAME_ST,
        payload: data,
    };
};

export const setRulerPointsPosDataST = (data) => {
    return {
        type: SET_RULER_POINTS_POS_DATA_ST,
        payload: data,
    };
};

export const setRulerLineStringST = (data) => {
    return {
        type: SET_RULER_LINE_STRING_ST,
        payload: [...data],
    };
};

export const setLampHideShowData = (data) => {
    return {
        type: SET_LAMP_HIDE_AND_SHOW_DATA_ST,
        payload: data,
    };
};

export const setHideRulerPopupST = (state) => {
    return {
        type: SET_RULER_HIDE_ALL_POPUP,
        payload: state,
    };
};

export const setMapStyleStatus = (state) => {
    return {
        type: SET_MAP_STYLES_STATUS,
        payload: state,
    };
};

export const setTimeLineZoomST = (state) => {
    return {
        type: SET_TIMELINE_ZOOM_ST,
        payload: state,
    };
};

export const setTimeLineZoomIndexST = (state) => {
    return {
        type: SET_TIMELINE_ZOOM_INDEX_ST,
        payload: state,
    };
};

export const setTimeLineMoveCountST = (state) => {
    return {
        type: SET_TIMELINE_MOVE_COUNT_ST,
        payload: state,
    };
};

export const setTimeLineCursorStatusST = (state) => {
    return {
        type: SET_TIMELINE_CURSOR_STATUS_ST,
        payload: state,
    };
};

export const setTimeLineEventDataST = (data) => {
    return {
        type: SET_TIMELINE_EVENT_DATA_ST,
        payload: data,
    };
};

export const setTimeLienMovePos = (data) => {
    return {
        type: SET_TIMELINE_MOVE_POSITION,
        payload: data,
    };
};

export const setTimeLIneMoveStatus = (state) => {
    return {
        type: SET_TIMELINE_MOVE_STATUS,
        payload: state,
    };
};

export const setTimeLineScrollZoomST = (state) => {
    return {
        type: SET_TIMELINE_SCROLL_ZOOM,
        payload: state,
    };
};

export const setTimeLIneZoomLevelST = (state) => {
    return {
        type: SET_TIMELINE_ZOOM_LEVEL,
        payload: state,
    };
};

export const setScreenShotLoading = (state) => {
    return {
        type: SET_SCREEN_SHOT_LOADING,
        payload: state,
    };
};

export const setMapCenterPositionST = (coordinates) => {
    return {
        type: SET_MAP_CENTER_POSITION,
        payload: coordinates,
    };
};

export const setMapCenterPositionStatusST = (coordinates) => {
    return {
        type: SET_MAP_CENTER_POSITION_STATUS,
        payload: coordinates,
    };
};

export const setScreenShotSendDataST = (data) => {
    return {
        type: SET_SCREENSHOT_SEND_DATA,
        payload: data,
    };
};

export const setScreenShotIntermediateDataST = (data) => {
    return {
        type: SET_SCREENSHOT_INTERMEDIATE_DATA,
        payload: data,
    };
};

export const setScreenShotButtonClickST = (data) => {
    return {
        type: SET_SCREENSHOT_BUTTON_CLICK,
        payload: data,
    };
};

export const setScreenShotLoader = (state) => {
    return {
        type: SET_SCREENSHOT_LOADER,
        payload: state,
    };
};

export const setCategoriesSortingST = (state) => {
    return {
        type: SET_CATEGORIES_SORTING,
        payload: state,
    };
};

export const setLampSortingST = (state) => {
    return {
        type: SET_LAMP_SORTING,
        payload: state,
    };
};

export const setRemoveCardId = (id) => {
    return {
        type: SET_REMOVE_CARD_ID,
        payload: id,
    };
};

// export const setTimeLineZoomChange = (data) => {
//   return {
//     type: SET_TIMELINE_ZOOM_CHANGE,
//     payload: data,
//   };
// };

export const setTimeLineMoveDataST = (state) => {
    return {
        type: SET_TIMELINE_MOVE_DATA_ST,
        payload: state,
    };
};

export const setTimeLineMoveDataStatusST = (state) => {
    return {
        type: SET_TIMELINE_MOVE_DATA_STATUS_ST,
        payload: state,
    };
};

export const setTopicStaticTitleST = (state) => {
    return {
        type: SET_TOPIC_STATIC_TITLE_ST,
        payload: state,
    };
};

export const setTimeLIneClickState = (state) => {
    return {
        type: SET_TIMELINE_CLICK_STATE,
        payload: state,
    };
};

export const setMobileMenuClose = (state) => {
    console.log("setMobileMenuClose", state);
    return {
        type: SET_MOBILE_MENU_CLOSE,
        payload: state,
    };
};

export const setTimeLineHeightST = (state) => {
    return {
        type: SET_TIMELINE_HEIGHT,
        payload: state,
    };
}

export const setTimelineBlocksScrollPositionST = (state) => {
    return {
        type: SET_TIMELINE_BLOCKS_SCROLL_POSITION,
        payload: state,
    };
}

export const setTimeLineMidBlockParamsST = (state) => {
    return {
        type: SET_TIMELINE_MID_BLOCK_PARAMS,
        payload: state,
    };
}

export const getScreenShotDataFromAPI =
    (page, id, onClose, state = true) =>
        (dispatch) => {
            state && dispatch(setScreenShotLoader(false));

            const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/DataProvider`;
            const body = {
                page: page,
                perPage: 10,
                sort: [],
                columnFilters: {},
                userIds: [id],
            };

            getScreenShotData(url, body)
                .then((data) => {
                    dispatch(setMapStateData(data));
                    onClose?.();
                })
                .catch((err) => console.log("err", err))
                .finally(() => state && dispatch(setScreenShotLoader(true)));
        };

export const getScreenShotSingleDataAPI = (id) => async  (dispatch) => {
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${id}`;

    return await getScreenShotSingleData(url)
                .then((data) => {
                    if (Object.keys(data).length > 0) {
                        const info = parse(data.data);
                        dispatch(setMapStateSingleData(data));
                        dispatch(setTopicStaticTitleST(data.title));
                        dispatch(setScreenShotSendDataST(stringify(data)));
                        info.getMapStyledId && dispatch(changeMapStyles(info.getMapStyledId));
                        info.getMapStyledId &&
                        dispatch(setMapStyleStatus(info.getMapStyledId));
                        info.getMapStyledId &&
                          dispatch(setMapStylesDark(info.getMapStyledId));
                        info.getMapStyledId &&
                        dispatch(setMapStylesIdST(info.getMapStyledId));
                        info.getMapStyledDarkId &&
                        dispatch(setMapStylesDark(info.getMapStyledDarkId));
                        info.getMapStyledDarkId &&
                        dispatch(setMapStylesDarkId(info.getMapStyledDarkId));
                        const runScreenShot = () =>{
                                function delay(ms) {
                                    return new Promise(resolve => setTimeout(resolve, ms));
                                }
                                const newPromise = delay(0)
                                newPromise
                                    .then(()=>{
                                        info.selectedLesson && dispatch(setSelectedLesson(info.selectedLesson));
                                        return delay(100)
                                    })
                                    .then(()=>{
                                        dispatch(setScreenShotSendDataST(stringify(data)));
                                        info.getTimeLineHeightST && dispatch(setTimeLineHeightST(info.getTimeLineHeightST));
                                        return delay(100)
                                    })
                                    .then(()=> {

                                        info.menuExpend && dispatch(setMenuExpend(info.menuExpend));

                                        info.hints && dispatch(setHints(info.hints));
                                        info.getLampDataScreenShot.length > 0 &&
                                        dispatch(setLampDataStateScreenShot(info.getLampDataScreenShot));
                                        info.getLampSelectedId &&
                                        dispatch(setLampSelectedId(info.getLampSelectedId));
                                        // info.lampData.length > 0 && dispatch(setLamp(info.lampData));

                                        info.getLampModalState && dispatch(setLampModalState(info.getLampModalState));

                                        info.getRulerPopupShowST &&
                                        dispatch(setHideRulerPopupST(info.getRulerPopupShowST));

                                        info.mapRequirement &&
                                        dispatch(setMapRequirement(info.mapRequirement));
                                        info.mapRequirements?.length > 0 &&
                                        dispatch(changeMapRequirementsScreenShot(info.mapRequirements));

                                        info.getCategoryArticleClickedId &&
                                        dispatch(getArticleFromAPI(info.getCategoryArticleClickedId));

                                        info.mapRequirements?.length > 0 &&
                                        dispatch(
                                            changeMapRequirements(
                                                info.mapRequirements.find((item) => item.active).value
                                            )
                                        );
                                        if (info.getLampData) {
                                            dispatch(setLampHideShowData(info.getLampData));
                                        }
                                        info.lampDataFiltered.length > 0 &&
                                        dispatch(
                                            setLampDataFilteredScreenShot([...info.lampDataFiltered])
                                        );

                                        info.getLampSortedTooltipState &&
                                        dispatch(
                                            setLampSortedTooltipState(info.getLampSortedTooltipState)
                                        );
                                        info.search && dispatch(setSearch(info.search));
                                        info.getSearchText && dispatch(setSearchText(info.getSearchText));
                                        info.getSearchCategoryId && dispatch(setSearchCategoryId(info.getSearchCategoryId));
                                        dispatch(setLampModalStateIndex(info.getLampModalStateIndex || 0));
                                        //painter
                                        info.painterStart && dispatch(setPainterStart(info.painterStart));
                                        info.menu && dispatch(setMenu(info.menu));
                                        return delay(3000)
                                    })
                                    .then(()=> {
                                        info.getStartPaintClickState &&
                                        dispatch(setStartPaintClickState(info.getStartPaintClickState));
                                        info.getPainterColor &&
                                        dispatch(setPointerColor(info.getPainterColor));
                                        info.getPainterColorData.length > 0 &&
                                        dispatch(setPointerColorData(info.getPainterColorData));
                                        info.getPainterSize &&
                                        dispatch(setPointerSize(info.getPainterSize));
                                        info.getPainterSizeData.length > 0 &&
                                        dispatch(setPointerSizeData(info.getPainterSizeData));

                                        info.getColorPickerShowState &&
                                        dispatch(setColorPickerShowState(info.getColorPickerShowState));
                                        info.getRulerClickedState &&
                                        dispatch(setRulerClickedState(info.getRulerClickedState));
                                        info.getEraserClickedState &&
                                        dispatch(setEraserClickState(info.getEraserClickedState));

                                        Object.keys(info.getPainterGeoJsonDataST).length > 0 &&
                                        dispatch(setPainterGeoJsonDataST(info.getPainterGeoJsonDataST));
                                        return delay(700)
                                    })
                                    .then(()=> {
                                        info.getRulerPointsPosDataST.length > 0 &&
                                        dispatch(
                                            setRulerPointsPosDataST([...info.getRulerPointsPosDataST])
                                        );
                                        console.log(info.getRulerGeoJsonDataST,'info.getRulerGeoJsonDataST')
                                        Object.keys(info.getRulerGeoJsonDataST).length > 0 &&
                                        dispatch(setRulerGeoJsonDataST(info.getRulerGeoJsonDataST));
                                        return delay(1000)
                                    })
                                    .then(()=> {
                                        info.quizPopup && dispatch(setQuizPopup(info.quizPopup));
                                        return delay(100)
                                    })
                                    .then(()=> {
                                        if(info.filteredCategories?.length > 0)
                                        {
                                            const newProxy = new arrayProxy(info.filteredCategories)
                                            newProxy.updateIndex(info?.filteredCategoriesProxyData)
                                            dispatch(setFilteredCategories(newProxy));
                                            dispatch(setFilteredCategoriesST(newProxy));
                                        }
                                        if (info.filteredLegends?.length > 0) {
                                            const newProxy = new LegendArrayProxy(info.filteredLegends)
                                            newProxy.updateIndex(info?.filteredLegendsProxyData)
                                            dispatch(setFilteredLegends(newProxy));
                                        }
                                        info.getCategoriesSortingST &&
                                        dispatch(setCategoriesSortingST(info.getCategoriesSortingST));

                                        Object.keys(info.getCategoriesPopupScrollPosition)?.length > 0 &&
                                        dispatch(
                                            setCategoriesPopupScrollPosition({
                                                status: true,
                                                y: info.getCategoriesPopupScrollPosition.y,
                                            })
                                        );
                                        dispatch(setTimeLineExpend(info.timelineExtend));
                                        info.categoryOpenID &&
                                        dispatch(setOpenCategoryID(info.categoryOpenID));
                                        return delay(100)
                                    })
                                    .then(()=> {
                                        Object.keys(info.getTimeLineMovieDataST).length &&
                                        dispatch(setTimeLineMoveDataStatusST(true));
                                        Object.keys(info.getTimeLineMovieDataST).length &&
                                        dispatch(setTimeLineMoveDataST(info.getTimeLineMovieDataST));
                                        return delay(100)
                                    })
                                    .then(()=> {

                                        info.getTimeTineZoomLevelST &&
                                        dispatch(setTimeLIneZoomLevelST(info.getTimeTineZoomLevelST));

                                        Object.keys(info.getTimeLineScrollZoomST).length > 0 &&
                                        dispatch(setTimeLineScrollZoomST(info.getTimeLineScrollZoomST));
                                        info.getTimeLineBlocksScrollPositionST && dispatch(setTimelineBlocksScrollPositionST(info.getTimeLineBlocksScrollPositionST));
                                        info.getTimeLineMidBlockParamsST && dispatch(setTimeLineMidBlockParamsST(info.getTimeLineMidBlockParamsST));
                                        return delay(100)
                                    })
                                    .then(()=> {

                                        Object.keys(info.getTimeLineMovePositionST).length > 0 &&
                                        dispatch(setTimeLIneMoveStatus(true));

                                        Object.keys(info.getTimeLineMovePositionST).length > 0 &&
                                        dispatch(
                                            setTimeLienMovePos({
                                                start: info.getTimeLineMovePositionST.start,
                                                end: info.getTimeLineMovePositionST.end,
                                            })
                                        );
                                        return delay(100)
                                    })
                                    .then(()=> {

                                        Object.keys(info.getMapCenterCoordinatesST).length > 0 &&
                                        dispatch(setMapCenterPositionStatusST(true));
                                        Object.keys(info.getMapCenterCoordinatesST).length > 0 &&
                                        dispatch(
                                            setMapCenterPositionST(info.getMapCenterCoordinatesST)
                                        );

                                        info.getLampSortingST &&
                                        dispatch(setLampSortingST(info.getLampSortingST));
                                        return delay(1000)
                                    })
                                    .then(()=> {

                                        info.getNewAngle &&
                                        dispatch(setCompassNewAngle(info.getNewAngle));
                                        Object.keys(info.getCompassCoordinates).length &&
                                        dispatch(setCompassCoordinates(info.getCompassCoordinates));
                                        return delay(100)
                                    })
                                    .then(()=> {

                                        info.getTimeLineZoomST &&
                                        dispatch(setTimeLineZoomST(info.getTimeLineZoomST));
                                        return delay(100)
                                    })
                                    .then(()=> {

                                        Object.keys(info.getTimeLineEventDataST).length > 0 &&
                                        dispatch(setTimeLineEventDataST(info.getTimeLineEventDataST));
                                        return delay(500)
                                    })
                                    .then(()=> {
                                        info.getNewRotate &&
                                        dispatch(setCompassRotate(info.getNewRotate));
                                        return delay(100)
                                    })
                                    .then(()=> {

                                        info.articleData.length > 0 &&
                                        dispatch(setArticles(info.articleData));

                                        info.articleData.length > 0 &&
                                        startPinAnimation(`article_${info.articleData[0].id}`);
                                        return delay(100)
                                    })
                                    .then(()=> {
                                        info.getZoomDataST && dispatch(setMapZoom(info.getZoomDataST));
                                        return delay(100)
                                    })
                                    .then(()=> {
                                        dispatch(setScreenShotLoading(false));
                                        (info.getMapSelectedCluster?.lat && Object.keys(info.getMapSelectedCluster).length) && dispatch(setMapSelectedCluster({
                                            ...info.getMapSelectedCluster,
                                            forScreenShoot: true
                                        }));
                                        return delay(1000)
                                    })
                                    .then(()=> {

                                        info.getTimeLineMoveCountST &&
                                        dispatch(setTimeLineMoveCountST(info.getTimeLineMoveCountST));
                                        return delay(100)
                                    })
                                    .then(()=> {

                                        info.getTimeLineCursorStatusST &&
                                        dispatch(
                                            setTimeLineCursorStatusST(info.getTimeLineCursorStatusST)
                                        );
                                        return delay(100)
                                    })

                        }
                        return runScreenShot
                    } else {
                            alert("Պահպանված քարտեզ գոյություն չունի");
                            window.close();
                    }

                })
                .catch((err) => console.log("err--", err));
};

export const createScreenShotAPI = (title, data) => (dispatch) => {
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState`;

    const body = {
        title: title || 'empty',
        data: stringify(data),
    };

    createScreenShot(url, body).then((data) => {
        dispatch(setScreenShotButtonStateST(false));
    });
};

export const removeScreenShotCardAPI =
    (pageLoc, cardId, userId, onClose) => (dispatch) => {
        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${cardId}`;

        removeScreenShot(url).then((data) => {
            dispatch(setRemoveCardId(cardId));

            // dispatch(getScreenShotDataFromAPI(pageLoc, userId, onClose, false));
        });
    };

export const editScreenShotCardAPI =
    (pageLoc, userId, id, title, data, onClose) => (dispatch) => {
        const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${id}`;
        const body = {
            title,
            data: data,
        };

        editScreenShot(url, body).then((data) => {
            dispatch(getScreenShotDataFromAPI(pageLoc, userId, onClose, false));
        });
    };

export const editScreenDataAPI = (id, title, data, onClose) => (dispatch) => {
    const url = `${process.env.REACT_APP_IDENTITY_URL}/api/MapState/${id}`;

    const body = {
        title,
        data: stringify(data),
    };

    editScreenShot(url, body).then((data) => {
        dispatch(setScreenShotButtonStateST(false));
    });
};

const startPinAnimation = (id) => {
    let mapMarkers = document.getElementsByClassName(
        "mapboxgl-marker-anchor-center"
    );

    for (let i = 0; i < mapMarkers.length; i++) {
        if (mapMarkers[i].getAttribute("class").includes(id)) {
            mapMarkers[i].classList.add("tic_animation");
            mapMarkers[i].style.zIndex = "2";
            continue;
        }
        mapMarkers[i].classList.remove("tic_animation");
        mapMarkers[i].style.zIndex = "1";
    }
};
