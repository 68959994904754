import { Pagination } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, { Component, useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import AccountBoard from "../../components/UI/AccountBoards";
import AccountPage from "../../components/UI/AccountPage";
import Bookmark from "../../components/UI/Bookmark";
import BookmarkCard from "../../components/UI/BookmarkCard";
import Logout from "../../components/UI/LogOut";
import PageLoader from "../../components/UI/PageLoader";
import LeftNavigation from "../../components/leftNavigation";
import { HandleToken, LogOut } from "../../helpers";
import {
  getBookmarksFromAPI,
  handleGetNewBookmarks,
  handleSetBookmarks,
} from "../../store/actions/bookmarks";
import { getCategoriesFromAPI } from "../../store/actions/categories";
import { getUserFromAPI } from "../../store/actions/user";
import {
  getBookmarksData,
  getBookmarksLoading,
  getUserData,
} from "../../store/selectors";
import Account from "../account";
import AccountFooter from "./../../components/accountFooter/index";
import "./style.css";
import DynamicScreenshot from "../../components/UI/DynamicScreenshot";

export const withNavigation = (Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};
let timeOutGetBookmark = null;
let timeOutSetBookmark = null;
const Bookmarks = (props) => {
  const matches = useMediaQuery("(min-width:600px)");
  const [page, setPage] = useState(1);
  const [paginationSize, setPaginationSize] = useState("medium");
  const handleChangePage = (e, value) => {
    const pageLoc = +value;
    if (!isNaN(pageLoc) && typeof pageLoc === "number" && pageLoc !== page) {
      setPage(() => pageLoc);
      props.getBookmarksFromAPI(pageLoc);
      window.history.replaceState(null, null, "?page=" + pageLoc);
    }
  };
  const handleUpdateBookmarkList = () => {
    const queryParams = new URLSearchParams(window.location.search);
    if (timeOutGetBookmark) clearTimeout(timeOutGetBookmark);
    if (timeOutSetBookmark) clearTimeout(timeOutSetBookmark);
    timeOutGetBookmark = setTimeout(() => {
      props.handleGetNewBookmarks(+queryParams.get("page")).then((res) => {
        timeOutSetBookmark = setTimeout(() => {
          props.handleSetBookmarks(res);
        }, 800);
      });
    }, 500);
  };
  useLayoutEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let pageLoc = +queryParams.get("page");
    if (pageLoc > Math.ceil(props.bookmarks.totalLength / 10))
      pageLoc = Math.ceil(props.bookmarks.totalLength / 10);
    if (pageLoc < 1) pageLoc = 1;
    if (!isNaN(pageLoc) && typeof pageLoc === "number") {
      if (pageLoc !== page) setPage(() => pageLoc);
      props.getCategoriesFromAPI();
      props.getBookmarksFromAPI(pageLoc);
      window.history.replaceState(null, null, "?page=" + pageLoc);
    }
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    let pageLoc = +queryParams.get("page");
    if (pageLoc > Math.ceil(props.bookmarks.totalLength / 10)) {
      pageLoc = Math.ceil(props.bookmarks.totalLength / 10);
      if (!isNaN(pageLoc) && typeof pageLoc === "number") {
        if (pageLoc !== page) setPage(() => pageLoc);
        window.history.replaceState(null, null, "?page=" + pageLoc);
        props.getBookmarksFromAPI(pageLoc);
      }
    }
  }, [props.bookmarks]);

  useEffect(() => {
    HandleToken();
    if (!props.user) {
      props.getUserFromAPI();
    }
  }, []);
  return (
    <Account onlyBG={true}>
      <LeftNavigation />
      <div className="top-btns">
        <div className="top-btns-left-bar">
          <div className={`main__tab `}>
            <Link to="/account-boards">
              <div className="main__tab-btn">
                <AccountBoard />
              </div>
              <div className="main__tab-btn__title">
                Թեմաներ ԵՎ <br /> դասեր
              </div>
            </Link>
          </div>

          <div className={`main__tab bookmarks__main-tab active`}>
            <Link to="/bookmarks?page=1">
              <div className="main__tab-btn">
                <Bookmark />
              </div>
              <div className="main__tab-btn__title">
                Պահպանված <br /> Հոդվածներ
              </div>
            </Link>
          </div>
          <div className={`main__tab dynamic-screenshot__main-tab`}>
            <Link to={`/dynamic-screenshots?page=${page}`}>
              <div className="main__tab-btn ">
                <DynamicScreenshot />
              </div>
              <div className="main__tab-btn__title">
                պահպանված <br /> քարտեզներ
              </div>
            </Link>
          </div>
        </div>
        <div className="top-btns-right-bar">
          <div className={`main__tab`}>
            <Link to="/account-page">
              <div className="main__tab-btn">
                <AccountPage />
              </div>
              <div className="main__tab-btn__title">
                Անձնական <br /> էջ
              </div>
            </Link>
          </div>
          <div className={`main__tab`}>
            <button type="button" onClick={LogOut}>
              <div className="main__tab-btn">
                <Logout />
              </div>
              <div className="main__tab-btn__title">դուրս գալ</div>
            </button>
          </div>
        </div>
      </div>
      <div className="bookmark-dashboards">
        <div className="account-dashboards--content">
          {!props.bookmarksIsLoading ? (
            props.bookmarks.length !== 0 ? (
              props.bookmarks.map((el) => (
                <BookmarkCard
                  key={el.id}
                  item={el}
                  handleUpdateBookmarkList={handleUpdateBookmarkList}
                />
              ))
            ) : (
              <div className={"bookmark-empty-data"}>
                <h2>Պահպանված Հոդվածներ չեն գտնվել</h2>
              </div>
            )
          ) : (
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                width: "100%",
                height: "100%",
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
                zIndex: 1222,
              }}
            >
              <PageLoader />
            </div>
          )}
        </div>
        <div className="account-dashboards--pagination">
          {!props.bookmarksIsLoading &&
            Math.ceil(props.bookmarks.totalLength / 10) > 0 && (
              <>
                <Pagination
                  className={"small"}
                  count={Math.ceil(props.bookmarks.totalLength / 10)}
                  size="small"
                  page={page}
                  onChange={handleChangePage}
                />
                <Pagination
                  className={"medium"}
                  count={Math.ceil(props.bookmarks.totalLength / 10)}
                  page={page}
                  onChange={handleChangePage}
                />
              </>
            )}
        </div>
      </div>
      <AccountFooter />
    </Account>
  );
};
const mapStateTopProps = (state) => ({
  user: getUserData(state),
  bookmarks: getBookmarksData(state),
  bookmarksIsLoading: getBookmarksLoading(state),
});

const mapDispatchToProps = {
  getUserFromAPI,
  getBookmarksFromAPI,
  getCategoriesFromAPI,
  handleGetNewBookmarks,
  handleSetBookmarks,
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withNavigation(Bookmarks));
