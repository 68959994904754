import { memo, useCallback, useEffect, useState, useRef } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { ARTICLE_READ_MORE_GALLERY } from "../../articleReadMore/constants";
import Loader from "../Loader";
import styles from "./style.module.css";

export const LinkStyleDot = (props) => {
  const { content = "empty box", url = "#" } = props;
  return (
    <a href={url} className={styles.link}>
      <span className={styles.dot}></span>
      <span className={styles.linkContent}>{content}</span>
    </a>
  );
};
export const List = memo((props) => {
  const { content = [] } = props;
  return (
    <ul>
      {content?.length ? (
        content?.map((el) => (
          <li>
            <LinkStyleDot key={el.id} url={el.url} content={el.name} />
          </li>
        ))
      ) : (
        <div className={styles.rightBarSectionEmpty}>
          <span>Ոչինչ չի գտնվել</span>
        </div>
      )}
    </ul>
  );
});
export const RenderNavigationSlides = (props) => {
  const { gallery, toggleGallery } = props;
  return (
    <>
      {gallery?.map((slide) => (
        <SplideSlide
          key={slide?.id}
          className={`${styles.galleryItem} ${styles.singleGallery} ${slide.mediaId}`}
          onClick={() => toggleGallery(slide?.id)}
          data-id={slide?.id}
        >
          {slide.type === 0 ? (
            <div className={styles.singleGalleryImg}>
              <img src={slide?.path} alt={slide.caption} />
            </div>
          ) : slide.type === 1 ? (
            <div className={`${styles.singleGalleryVideo} ${styles.isVideo}`}>
              <video
                data-item={true}
                src={slide?.path}
                className="hiddConroll"
                controls="hidden"
                style={{ pointerEvents: "none" }}
              />
              <div className={"single-gallery-video-iconPath"}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="260px"
                  height="260px"
                  viewBox="0 0 260 260"
                  version="1.1"
                >
                  <title>video</title>
                  <defs>
                    <filter
                      x="-201.2%"
                      y="-201.2%"
                      width="502.5%"
                      height="502.5%"
                      filterUnits="objectBoundingBox"
                      id="filter-1"
                    >
                      <feOffset
                        dx="0"
                        dy="14"
                        in="SourceAlpha"
                        result="shadowOffsetOuter1"
                      />
                      <feGaussianBlur
                        stdDeviation="26"
                        in="shadowOffsetOuter1"
                        result="shadowBlurOuter1"
                      />
                      <feColorMatrix
                        values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.1 0"
                        type="matrix"
                        in="shadowBlurOuter1"
                        result="shadowMatrixOuter1"
                      />
                      <feMerge>
                        <feMergeNode in="shadowMatrixOuter1" />
                        <feMergeNode in="SourceGraphic" />
                      </feMerge>
                    </filter>
                  </defs>
                  <g
                    id="Page-1"
                    stroke="none"
                    strokeWidth="1"
                    fill="none"
                    fillRule="evenodd"
                    opacity="0.8"
                    strokeDasharray="0,0"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <g
                      id="Read-more"
                      transform="translate(-1820.000000, -1708.000000)"
                      fillRule="nonzero"
                      stroke="#FFFFFF"
                      strokeWidth="6"
                    >
                      <g
                        id="Group-53"
                        transform="translate(208.939531, 182.000000)"
                      >
                        <g
                          id="Group-52"
                          transform="translate(0.060469, 1496.000000)"
                        >
                          <g
                            id="Group-3"
                            transform="translate(1499.956434, 0.289943)"
                          >
                            <g
                              id="vuesax/linear/video-square"
                              transform="translate(203.902627, 127.102368)"
                            >
                              <g
                                transform="translate(-0.043947, 0.175788)"
                                id="video"
                                filter="url(#filter-1)"
                              >
                                <path
                                  d="M41.9912836,64.6019748 C58.1417773,64.6019748 64.6019748,58.1417773 64.6019748,41.9912836 L64.6019748,22.6106912 C64.6019748,6.46019748 58.1417773,0 41.9912836,0 L22.6106912,0 C6.46019748,0 0,6.46019748 0,22.6106912 L0,41.9912836 C0,58.1417773 6.46019748,64.6019748 22.6106912,64.6019748 L41.9912836,64.6019748 Z"
                                  id="Vector"
                                />
                                <path
                                  d="M22.9340165,27.5204135 C22.9340165,21.350925 27.2946499,18.8637475 32.6243127,21.9323412 L36.7588382,24.3226134 L40.8933637,26.7128888 C46.2230266,29.7814825 46.2230266,34.8204337 40.8933637,37.8890275 L36.7588382,40.2793029 L32.6243127,42.6695752 C27.2946499,45.738169 22.9340165,43.2186903 22.9340165,37.0815028 L22.9340165,32.3009581 L22.9340165,27.5204135 Z"
                                  id="Vector"
                                />
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </g>
                </svg>
              </div>
            </div>
          ) : slide.type === 3 ? (
            <div className={`${styles.singleGalleryIframe}`}>
              <iframe
                className="is-video"
                src={slide?.path}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;fullscreen;"
              />
            </div>
          ) : null}
        </SplideSlide>
      ))}
    </>
  );
};
export const SplideArrows = () => {
  return (
    <div
      className="splide__arrows"
      style={{
        justifyContent: "center",
        display: "flex",
      }}
    >
      <button
        className="gallery-btn prev-main splide__arrow--prev"
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94px"
          height="124px"
          viewBox="0 0 94 124"
          version="1.1"
        >
          <title>arrow-right</title>
          <defs>
            <filter
              x="-139.5%"
              y="-69.4%"
              width="379.1%"
              height="238.8%"
              filterUnits="objectBoundingBox"
              id="filter-1"
            >
              <feOffset
                dx="0"
                dy="0"
                in="SourceAlpha"
                result="shadowOffsetOuter1"
              />
              <feGaussianBlur
                stdDeviation="10"
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.5 0"
                type="matrix"
                in="shadowBlurOuter1"
                result="shadowMatrixOuter1"
              />
              <feMerge>
                <feMergeNode in="shadowMatrixOuter1" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeDasharray="0,0"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g
              id="Read-more"
              transform="translate(-229.000000, -859.000000)"
              fillRule="nonzero"
              stroke="#FFFFFF"
              strokeWidth="5.28"
            >
              <g
                id="arrow-right"
                filter="url(#filter-1)"
                transform="translate(276.000000, 920.921378) rotate(-540.000000) translate(-276.000000, -920.921378) translate(261.078622, 890.921378)"
              >
                <path d="M0,0 L29.8427564,30 L0,60" id="Vector" />
              </g>
            </g>
          </g>
        </svg>
      </button>
      <button
        className="gallery-btn next-main splide__arrow--next"
        type="button"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="94px"
          height="124px"
          viewBox="0 0 94 124"
          version="1.1"
        >
          <title>arrow-right copy</title>
          <defs>
            <filter
              x="-139.5%"
              y="-69.4%"
              width="379.1%"
              height="238.8%"
              filterUnits="objectBoundingBox"
              id="filter-1"
            >
              <feOffset
                dx="0"
                dy="0"
                in="SourceAlpha"
                result="shadowOffsetOuter1"
              />
              <feGaussianBlur
                stdDeviation="10"
                in="shadowOffsetOuter1"
                result="shadowBlurOuter1"
              />
              <feColorMatrix
                values="0 0 0 0 0.17254902   0 0 0 0 0.278431373   0 0 0 0 0.423529412  0 0 0 0.5 0"
                type="matrix"
                in="shadowBlurOuter1"
                result="shadowMatrixOuter1"
              />
              <feMerge>
                <feMergeNode in="shadowMatrixOuter1" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
            strokeDasharray="0,0"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <g
              id="Read-more"
              transform="translate(-2578.000000, -853.000000)"
              fillRule="nonzero"
              stroke="#FFFFFF"
              strokeWidth="5.28"
            >
              <g
                id="arrow-right-copy"
                filter="url(#filter-1)"
                transform="translate(2625.000000, 915.042653) rotate(-360.000000) translate(-2625.000000, -915.042653) translate(2610.078622, 885.042653)"
              >
                <path d="M0,0 L29.8427564,30 L0,60" id="Vector" />
              </g>
            </g>
          </g>
        </svg>
      </button>
    </div>
  );
};
export const Slider = (props) => {
    const {gallery = [],toggleGallery} = props
    const showItems = [...gallery?.filter((x) => x.type !== 4 && (!('visibility' in x) || x.visibility))]
    return (
        showItems && showItems.length > 0
        ? (<Splide
            key={showItems.length}
            options={{
                // type: 'loop',
                // start:this.state.startIdx,
                perPage: 5,
                perMove: 1,
                pagination: false,
                height: window.innerHeight*0.12,
                arrows: false,
                gap:'5px',
            }}
            renderControls={showItems.length > 5 && SplideArrows}
            // className="gallery-items"
        >
            {<RenderNavigationSlides gallery={showItems}   toggleGallery={toggleGallery}></RenderNavigationSlides>}
        </Splide>)
        : <p className = {styles.emptyData}>Ոչինչ չի գտնվել</p>
    )
}
export const GalleryFilterItem = (props) =>{
    const {info,changeSelectedFilter} = props
    return (
            <label htmlFor={`${info.type}${info.id}`} onClick={()=>changeSelectedFilter(info.id)}>
                <input id={`${info.type}${info.id}`} checked={info.checked} type="radio" name={'filter-type'} className={`${styles.filterInput}`}/>
                <div className={`${styles.filterbtn}`}>{info.title}</div>
            </label>
    )
}
export const GallerySection = (props) =>{
    const {gallery, changeFilterTypes, filterTypes, toggleGallery, filterGallery} = props

    return(
        <div className={`${styles.gallerySection}`}>
            <div className={`${styles.galleryFilterSection}`}>
                {
                    filterTypes?.map(el=>
                        <GalleryFilterItem checked={el.checked} info={el} changeSelectedFilter={changeFilterTypes} />
                    )
                }
            </div>

            <Slider toggleGallery={toggleGallery} gallery={gallery} />
        </div>
    )
}
export const RightBarBtnCenter = (props) =>{
    const {title} = props

  return <div className={styles.rightBarBtnCenter} dangerouslySetInnerHTML={{__html:title}}/>
};
export const RightBarBtnLeft = (props) => {
  const { title, iconPath, isMain } = props;
  return (
    <button
      className={`${styles.rightBarBtnLeft} ${isMain ? styles.isMain : null}`}
    >
      <span>
        <img src={iconPath} className={styles.rightBarBtnLeftIcon} />
      </span>{" "}
      <span className={styles.rightBarBtnLeftTitle}>{title}</span>
    </button>
  );
};
export const RightBarCategoryHeader = (props) => {
  const { content } = props;
  return (
    <div className={styles.rightBarCategoryHeader}>
      <span
        className={styles.rightBarCategoryHeaderIconBox}
        style={{ background: content?.color }}
      >
        <img
          src={content?.iconPath}
          className={styles.rightBarCategoryHeaderIcon}
        />
      </span>
    </div>
  );
};
export const RightBarBtnBox = (props) => {
  const { content, className } = props;
    return (
    <div className={`${styles.rightBarBtnBox} ${className || ""}`}>
      {content?.content?.length ? (
        content?.content?.map((el) => {
            return (
                <div key={el.id} className={styles.rightBarBtnBoxItem}>
                    {el?.iconPath && <RightBarCategoryHeader content={el} />}
                    <h3 className={styles.rightBarBtnBoxTitle}>{el?.title}</h3>
                    {
                        el?.subTitle && <small>( {el?.subTitle} )</small>
                    }
                    {el?.content && el?.content?.length
                        ? el.content?.map((item) =>
                            item?.iconPath ? (
                                <RightBarBtnLeft
                                    key={item.id}
                                    isMain={item?.isMain}
                                    title={
                                        item?.title ? item?.title : item?.content?.mainTitle
                                    }
                                    iconPath={item?.iconPath}
                                />
                            ) : (
                                <RightBarBtnCenter
                                    key={item.id}
                                    isMain={item?.isMain}
                                    title={
                                        item?.title ? item?.title : item?.content?.mainTitle
                                    }
                                />
                            )
                        )
                        : ""}
                </div>
            )
        })
      ) : (
        <span>Ոչինչ չի գտնվել</span>
      )}
    </div>
  );
};
export const RightBarTagItem = (props) => {
  const { title } = props;
  return <button className={styles.rightBarTagItem}>{title}</button>;
};
export const RightBarTags = (props) => {
  const { content } = props;
  return (
    <div className={styles.rightBarTagsSection}>
      {content?.content.length ? (
        content?.content[0]?.map((el) => (
          <RightBarTagItem key={el.id} title={el?.title} />
        ))
      ) : (
        <div className={styles.rightBarTagsSectionEmpty}>
          <span>Ոչինչ չի գտնվել</span>
        </div>
      )}
    </div>
  );
};
export const RightBarMapIframe = (props) => {
  const { content } = props;
  const origin = window.location.origin;
  const path = `/map?preview=true&type=article&articleID=${content.articleId}&articleReadMode=true`;
  return (
    <div className={"map-media"}>
      <iframe src={`${origin}${path}`}></iframe>
    </div>
  );
};
export const DangerouslySetInnerHTML = (props) => {
  const { content } = props;
  return content ? (
    <div dangerouslySetInnerHTML={{ __html: content.replace(/\n/g, '<br/>')}}></div>
  ) : (
    <p className={styles.emptyData}>Ոչինչ չի գտնվել</p>
  );
};
