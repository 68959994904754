import axios from "axios";

const extractItems = (res) => {
  const { data } = res;
  if (data.accepted) {
    if (data.data && data.data[0]) {
      return data.data[0];
    }
  }
  return [];
};

export const getScreenShotData = async (url, body) => {
  return await axios
    .post(url, body)
    .then((response) => extractItems(response))
    .catch((error) => console.log("error", error));
};
export const getScreenShotSingleData = async (url) => {
  return await axios
    .get(url)
    .then((response) => extractItems(response))
    .catch((error) => console.log("error", error));
};

export const createScreenShot = async (url, body) => {
  return axios
    .post(url, body)
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const removeScreenShot = async (url) => {
  return axios
    .delete(url)
    .then((response) => response)
    .catch((error) => console.log("error", error));
};

export const editScreenShot = async (url, body) => {
  return axios
    .put(url, body)
    .then((response) => response)
    .catch((error) => console.log("error", error));
};
