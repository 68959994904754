import {
  LOADING_TIMELINE,
  LOADING_MIDDLE_TIMELINE,
  SET_TIMELINE_ITEMS,
  SET_TIMELINE_GROUPS,
  SET_TIMELINE_ARTICLE,
  SET_MIDDLE_TIMELINE_ITEMS,
  SET_MAX_MIDDLE_TIMELINE_ITEMS
} from "../constants";
import { processTimeLineTopicItemsRendering } from "../../pages/map/utils/processTimeLineTopicItemsRendering";
import { processMiddleTimeLineTopicItemsRendering } from "../../pages/map/utils/processMiddleTimeLineTopicItemsRendering";
import { processTimeLineLessonGroupRendering } from "../../pages/map/utils/processTimeLineLessonGroupRendering";
import { processTimeLineLessonItemsRendering } from "../../pages/map/utils/processTimeLineLessonItemsRendering";
import {
    processMiddleTimeLineLessonItemsRendering
} from "../../pages/map/utils/processMiddleTimeLineLessonItemsRendering";
import {
    processMiddleMaxTimeLineLessonItemsRendering
} from "../../pages/map/utils/processMiddleMaxTimeLineLessonItemsRendering";
import {processTimeLineArticleItemsRendering} from "../../pages/map/utils/processTimeLineArticleItemsRendering";

export const loadingTimeLine = () => ({
  type: LOADING_TIMELINE,
});
export const loadingMiddleTimeLine = () => ({
    type: LOADING_MIDDLE_TIMELINE,
});

export const timeLineLoadingReaction = (bool) => console.log(bool,'bool') || ({
  type: LOADING_TIMELINE,
  payload: bool,
});

export const setTimeLineItems = (items) => ({
  type: SET_TIMELINE_ITEMS,
  payload: items,
});
export const setMiddleTimeLineItems = (items) => ({
  type: SET_MIDDLE_TIMELINE_ITEMS,
  payload: items,
});
export const setMiddleMaxTimeLineItems = (items) => ({
  type: SET_MAX_MIDDLE_TIMELINE_ITEMS,
  payload: items,
});

export const setTimeLineGroups = (items) => ({
  type: SET_TIMELINE_GROUPS,
  payload: items,
});

export const setTimeLineArticle = (items) => ({
  type: SET_TIMELINE_ARTICLE,
  payload: items,
});

export const setTimeLineArticlesData = (data,type) => async (dispatch,getState) => {
  dispatch(loadingTimeLine())
  await processTimeLineArticleItemsRendering(data,type,getState,dispatch)
      .then((items) => {
          dispatch(setTimeLineItems(items));
      })
};

export const setTimeLineTopicsData = (data,type) => async (dispatch,getState) => {
  dispatch(loadingTimeLine());
  await processTimeLineTopicItemsRendering(data,type,getState,dispatch)
      .then((items) => {
          dispatch(setTimeLineItems(items))
      });
  // const items = processTimeLineTopicItemsRendering(data)
  // dispatch(setTimeLineItems(items));
};
export const setMiddleTimeLineTopicsData = (data) => (dispatch) => {
    dispatch(loadingMiddleTimeLine());
    const items = processMiddleTimeLineTopicItemsRendering(data);
    dispatch(setMiddleTimeLineItems(items));
};

export const setMiddleTimeLineLessonsData = (data) => (dispatch,getState) => {
    const state = getState()
    const categories = state.categories.data
    dispatch(loadingMiddleTimeLine());
    const items = processMiddleTimeLineLessonItemsRendering(data,categories,getState,dispatch);
    dispatch(setMiddleTimeLineItems(items));
};

export const setMiddleMaxTimeLineLessonsData = (data) => (dispatch,getState) => {
    const state = getState()
    const categories = state.categories.data
    dispatch(loadingMiddleTimeLine());
    const items = processMiddleMaxTimeLineLessonItemsRendering(data,categories,getState,dispatch);
    dispatch(setMiddleMaxTimeLineItems(items));
};
export const setTimeLineLessonData = (item,dataId, lessonId, data, fromTopic,setTopicId) => async (dispatch, getState) => {
        const state = getState()
        const categories = state.categories.data
        dispatch(loadingTimeLine())
        const groupItems = await processTimeLineLessonGroupRendering(data, categories)
        // processTimeLineLessonItemsRendering(
        //     dataId,
        //     lessonId,
        //     data,
        //     fromTopic,
        //     groupItems,
        //     categories,
        //     setTopicId
        // ).then((items) => {
        //     dispatch(setTimeLineGroups(groupItems))
        //     dispatch(setTimeLineItems(items))
        // })
        processTimeLineLessonItemsRendering(
            dataId,
            lessonId,
            item,
            fromTopic,
            groupItems,
            categories,
            setTopicId,getState,dispatch)
            .then((items) => {
                    dispatch(setTimeLineGroups(groupItems))
                    dispatch(setTimeLineItems(items));
                });
    }

const _toggleGroup = (groups, items, id) => {
  const groupIndex = groups.findIndex((x) => x.catId === id);

  if (groups[groupIndex]?.show) {
    groups[groupIndex].show = !groups[groupIndex]?.show;

    items
      .filter((item) => item.catId === id)
      .forEach((item) => {
        item.show = groups[groupIndex].show;
      });
  }
};

export const updateTimeLineGroupToggle = (id) => (dispatch, getState) => {
  const state = getState();
  const groups = [...state.timeLineGroups.data];
  const items = [...state.timeLineItems.data];
  if (groups.length > 0) {
    _toggleGroup(groups, items, id);
    dispatch(setTimeLineGroups(groups));
    dispatch(setTimeLineItems(items));
  }
};
