import { parse, stringify } from "flatted";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useLocation, useSearchParams } from "react-router-dom";
import { log } from "react-zlib-js";
import ScreenShotIcon from "../../../../assets/imgs/screenshot-icon.svg";
import {
  createScreenShotAPI,
  setScreenShotSendDataST,
  setScreenShotIntermediateDataST,
  setScreenShotButtonClickST,
  setScreenShotButtonStateST,
  editScreenDataAPI,
} from "../../../../store/actions/mapStateAction";
import {
  getArticlesData,
  getCategoriesPopupScrollPosition,
  getCategoriesSortingST,
  getCategoryArticleClickedId,
  getColorPickerShowState,
  getCompassCoordinates,
  getEraserClickedState,
  getFilteredCategoriesData, getFilteredLegendsData,
  getHintsData,
  getLampData,
  getLampDataScreenShot,
  getLampHideAndShowDataST, getLampModalState, getLampModalStateIndex,
  getLampSelectedId,
  getLampSortedTooltipState,
  getLampSortingST,
  getMapCenterCoordinatesST,
  getMapRequirementData,
  getMapRequirementsData, getMapSelectedCluster,
  getMapStateSingleData,
  getMapStyledDarkId,
  getMapStyledId,
  getMenuData,
  getMenuExpendData,
  getNewAngle,
  getNewRotate,
  getOpenCategoryID,
  getPainterColor,
  getPainterColorData,
  getPainterGeoJsonDataST,
  getPainterSize,
  getPainterSizeData,
  getPainterStartData,
  getQuizPopupData,
  getRulerClickedState,
  getRulerGeoJsonDataST,
  getRulerLineStringST,
  getRulerPointsPosDataST,
  getRulerPopupShowST,
  getRulerSourceNameST,
  getRulerSourceSecondNameST,
  getScreenShotButtonClickST,
  getScreenShotIntermediateDataST,
  getScreenShotSendDataST, getSearchCategoryId,
  getSearchData,
  getSearchText,
  getSelectedLessonId,
  getStartPaintClickState, getTimeLineBlocksScrollPositionST,
  getTimeLineCursorStatusST,
  getTimeLineEventDataST,
  getTimeLineExpendData, getTimeLineHeightST,
  getTimeLineItemsData, getTimeLineMidBlockParamsST,
  getTimeLineMoveCountST,
  getTimeLineMovePositionST,
  getTimeLineMoveStatusST,
  getTimeLineMovieDataST,
  getTimeLineScrollZoomST,
  getTimeLineZoomIndexST,
  getTimeLineZoomST,
  getTimeTineZoomLevelST,
  getTopicsData,
  getTopicStaticTitleST,
  getZoomDataST,
} from "../../../../store/selectors";
import "./style.css";
import {setLampModalState} from "../../../../store/actions/lamp";

const MapScreenShotButton = (props) => {
  const {
    createScreenShotAPI,
    topics,
    timeLineItems,
    menuExpend,
    getOpenCategoryID,
    mapRequirement,
    mapRequirements,
    hints,
    quizPopup,
    getCategoriesPopupScrollPosition,
    getCategoryArticleClickedId,
    filteredCategories,
    getLampData,
    getLampSortedTooltipState,
    getLampSelectedId,
    search,
    getSearchText,
    getMapStyledId,
    getMapStyledDarkId,
    painterStart,
    getStartPaintClickState,
    getPainterColor,
    getPainterColorData,
    getPainterSize,
    getPainterSizeData,
    getColorPickerShowState,
    getRulerClickedState,
    getEraserClickedState,
    getPainterGeoJsonDataST,
    getRulerGeoJsonDataST,
    getRulerSourceNameST,
    getRulerSourceSecondNameST,
    getRulerPointsPosDataST,
    getRulerLineStringST,
    getLampDataScreenShot,
    getZoomDataST,
    getNewAngle,
    getNewRotate,
    getCompassCoordinates,
    getRulerPopupShowST,
    getTimeLineZoomST,
    getTimeLineZoomIndexST,
    getTimeLineMoveCountST,
    getTimeLineCursorStatusST,
    getTimeLineEventDataST,
    getTimeLineMovePositionST,
    getTimeLineMoveStatusST,
    getTimeLineScrollZoomST,
    getTimeTineZoomLevelST,
    timelineExtend,
    getMapCenterCoordinatesST,
    setScreenShotSendDataST,
    setScreenShotIntermediateDataST,
    getScreenShotButtonClickST,
    setScreenShotButtonClickST,
    setScreenShotButtonStateST,
    disable = "none",
    getCategoriesSortingST,
    getLampSortingST,
    selectedLesson,
    articleData,
    menu,
    getTimeLineMovieDataST,
    getScreenShotSendDataST,
    editScreenDataAPI,
    getTopicStaticTitleST,
    getMapSelectedCluster,
    getTimeLineHeightST,
    getTimeLineBlocksScrollPositionST,
    getLampModalState,
    getSearchCategoryId,
    getLampModalStateIndex,
    getTimeLineMidBlockParamsST,filteredLegends, getLampHideAndShowDataST
  } = props;

  const overlay = useRef();

  const params = useLocation();

  let [searchParams] = useSearchParams();
  let topicID = searchParams.get("topicID");
  let isScreenShot = searchParams.get("screenShot");

  const [topicTitle, setTopicTitle] = useState("");
  const [lessonTitle, setLessonTitle] = useState("");

  const url = `${params?.pathname}${params?.search}`;

  useEffect(() => {
    const data = topics.find((item) => item.value === +topicID);
    setTopicTitle(data?.title);
  }, [topics]);

  useEffect(() => {
    setLessonTitle(
      timeLineItems?.find((x) => x.className === "isGroup")?.titles?.lessonTitle
    );
    topics.length === 0 &&
      setTopicTitle(
        timeLineItems?.find((x) => x.className === "isGroup")?.titles
          ?.topicTitle
      );
  }, [timeLineItems]);

  useEffect(() => {
    document.body.addEventListener("mouseup", onDocumentBodyClick);

    document
      .getElementsByClassName("timeline__content")[0]
      .addEventListener("click", onDocumentBodyClick);

    return () => {
      document.body.removeEventListener("mouseup", onDocumentBodyClick);
      const timeLineContent = document
          .getElementsByClassName("timeline__content")[0];
      if (timeLineContent) {
        timeLineContent.removeEventListener("click", onDocumentBodyClick);
      }
    };
  }, [props]);

  const onDocumentBodyClick = (e) => {
    if (
      e.target.id === "screenShot_icon" ||
      e.target.id === "save_us_screenShot" ||
      e.target.id === "new_screenShot"
    )
      return;

    const lampDataFiltered = getLampData.map((item) => {
      return {
        id: item.id,
        isHide: item.isHide,
      };
    });

    let data = {
      url: url,
      getLampData: getLampData,
      topicTitle: topicTitle,
      topicStaticTitle: topicTitle,
      lessonTitle: lessonTitle,
      menuExpend: menuExpend,
      categoryOpenID: getOpenCategoryID,
      mapRequirement: mapRequirement,
      mapRequirements: mapRequirements,
      hints: hints,
      quizPopup: quizPopup,
      getCategoriesPopupScrollPosition: getCategoriesPopupScrollPosition,
      getCategoryArticleClickedId: getCategoryArticleClickedId,
      filteredCategories: filteredCategories,
      filteredCategoriesProxyData: filteredCategories?.getData(),
      filteredLegendsProxyData: filteredLegends?.getData(),
      lampDataFiltered: lampDataFiltered,
      getLampSortedTooltipState: getLampSortedTooltipState,
      getLampSelectedId: getLampSelectedId,
      search: search,
      getSearchText: getSearchText,
      getMapStyledId: getMapStyledId,
      getMapStyledDarkId: getMapStyledDarkId,
      painterStart: painterStart,
      getStartPaintClickState: getStartPaintClickState,
      getPainterColor: getPainterColor,
      getPainterColorData: getPainterColorData,
      getPainterSize: getPainterSize,
      getPainterSizeData: getPainterSizeData,
      getColorPickerShowState: getColorPickerShowState,
      getRulerClickedState: getRulerClickedState,
      getEraserClickedState: getEraserClickedState,
      getPainterGeoJsonDataST:
        Object.keys(getPainterGeoJsonDataST).length > 0
          ? getPainterGeoJsonDataST
          : {
              type: "FeatureCollection",
              features: [],
            },
      getRulerGeoJsonDataST: getRulerGeoJsonDataST,
      getRulerSourceNameST: getRulerSourceNameST,
      getRulerSourceSecondNameST: getRulerSourceSecondNameST,
      getRulerPointsPosDataST: getRulerPointsPosDataST,
      getRulerLineStringST: getRulerLineStringST,
      getLampDataScreenShot: getLampDataScreenShot,
      getZoomDataST: getZoomDataST,
      getNewAngle: getNewAngle,
      getNewRotate: getNewRotate,
      getCompassCoordinates: getCompassCoordinates,
      getRulerPopupShowST: getRulerPopupShowST,
      getTimeLineZoomST: getTimeLineZoomST,
      getTimeLineZoomIndexST: getTimeLineZoomIndexST,
      getTimeLineMoveCountST: getTimeLineMoveCountST,
      getTimeLineCursorStatusST: getTimeLineCursorStatusST,
      getTimeLineEventDataST: getTimeLineEventDataST,
      getTimeLineMovePositionST: getTimeLineMovePositionST,
      getTimeLineMoveStatusST: getTimeLineMoveStatusST,
      getTimeLineScrollZoomST: getTimeLineScrollZoomST,
      getTimeTineZoomLevelST: getTimeTineZoomLevelST,
      timelineExtend: timelineExtend,
      getMapCenterCoordinatesST: getMapCenterCoordinatesST,
      getCategoriesSortingST: getCategoriesSortingST,
      getLampSortingST: getLampSortingST,
      selectedLesson: selectedLesson,
      articleData: articleData,
      menu: menu,
      getTimeLineMovieDataST: getTimeLineMovieDataST,
      getMapSelectedCluster,
      getTimeLineHeightST,
      getTimeLineBlocksScrollPositionST,getLampModalState, getSearchCategoryId,getLampModalStateIndex, getTimeLineMidBlockParamsST,filteredLegends
    };
    setScreenShotIntermediateDataST(stringify(data));

    setScreenShotButtonStateST(true);
  };

  const handleOverlayBody = () => {
    const lampDataFiltered = getLampData.map((item) => {
      return {
        id: item.id,
        isHide: item.isHide,
      };
    });

    let data = {
      url: url,
      getLampData: getLampData,
      topicTitle: topicTitle,
      topicStaticTitle: topicTitle,
      lessonTitle: lessonTitle,
      menuExpend: menuExpend,
      categoryOpenID: getOpenCategoryID,
      mapRequirement: mapRequirement,
      mapRequirements: mapRequirements,
      hints: hints,
      quizPopup: quizPopup,
      getCategoriesPopupScrollPosition: getCategoriesPopupScrollPosition,
      getCategoryArticleClickedId: getCategoryArticleClickedId,
      filteredCategories: filteredCategories,
      filteredCategoriesProxyData: filteredCategories?.getData(),
      filteredLegendsProxyData: filteredLegends?.getData(),
      lampDataFiltered: lampDataFiltered,
      getLampSortedTooltipState: getLampSortedTooltipState,
      getLampSelectedId: getLampSelectedId,
      search: search,
      getSearchText: getSearchText,
      getMapStyledId: getMapStyledId,
      getMapStyledDarkId: getMapStyledDarkId,
      painterStart: painterStart,
      getStartPaintClickState: getStartPaintClickState,
      getPainterColor: getPainterColor,
      getPainterColorData: getPainterColorData,
      getPainterSize: getPainterSize,
      getPainterSizeData: getPainterSizeData,
      getColorPickerShowState: getColorPickerShowState,
      getRulerClickedState: getRulerClickedState,
      getEraserClickedState: getEraserClickedState,
      getPainterGeoJsonDataST:
        Object.keys(getPainterGeoJsonDataST).length > 0
          ? getPainterGeoJsonDataST
          : {
              type: "FeatureCollection",
              features: [],
            },
      getRulerGeoJsonDataST: getRulerGeoJsonDataST,
      getRulerSourceNameST: getRulerSourceNameST,
      getRulerSourceSecondNameST: getRulerSourceSecondNameST,
      getRulerPointsPosDataST: getRulerPointsPosDataST,
      getRulerLineStringST: getRulerLineStringST,
      getLampDataScreenShot: getLampDataScreenShot,
      getZoomDataST: getZoomDataST,
      getNewAngle: getNewAngle,
      getNewRotate: getNewRotate,
      getCompassCoordinates: getCompassCoordinates,
      getRulerPopupShowST: getRulerPopupShowST,
      getTimeLineZoomST: getTimeLineZoomST,
      getTimeLineZoomIndexST: getTimeLineZoomIndexST,
      getTimeLineMoveCountST: getTimeLineMoveCountST,
      getTimeLineCursorStatusST: getTimeLineCursorStatusST,
      getTimeLineEventDataST: getTimeLineEventDataST,
      getTimeLineMovePositionST: getTimeLineMovePositionST,
      getTimeLineMoveStatusST: getTimeLineMoveStatusST,
      getTimeLineScrollZoomST: getTimeLineScrollZoomST,
      getTimeTineZoomLevelST: getTimeTineZoomLevelST,
      timelineExtend: timelineExtend,
      getMapCenterCoordinatesST: getMapCenterCoordinatesST,
      getCategoriesSortingST: getCategoriesSortingST,
      getLampSortingST: getLampSortingST,
      selectedLesson: selectedLesson,
      articleData: articleData,
      menu: menu,
      getTimeLineMovieDataST: getTimeLineMovieDataST,
      getMapSelectedCluster,
      getTimeLineHeightST, getLampHideAndShowDataST,
      getTimeLineBlocksScrollPositionST, getLampModalState, getSearchCategoryId,getLampModalStateIndex, getTimeLineMidBlockParamsST,filteredLegends
    };

    setScreenShotIntermediateDataST(stringify(data));
    setScreenShotSendDataST(stringify(data));

    setTimeout(() => {
      setScreenShotButtonClickST(!getScreenShotButtonClickST);
    }, 1000);

    createScreenShotAPI(
      topicTitle ||
        timeLineItems?.find((x) => x.className === "isGroup")?.titles
          ?.lessonTitle,
      data
    );
    overlay.current.style = "display:block";
    setTimeout(() => {
      overlay.current.style = "display:none";
    }, 500);

    setScreenShotState(false);
  };

  const [screenShotState, setScreenShotState] = useState(false);

  const onScreenShotEdit = () => {
    const lampDataFiltered = getLampData.map((item) => {
      return {
        id: item.id,
        isHide: item.isHide,
      };
    });
    console.log("filteredLegends", filteredLegends);
    let data = {
      url: url,
      getLampData: getLampData,
      topicTitle: topicTitle,
      topicStaticTitle: topicTitle,
      lessonTitle: lessonTitle,
      menuExpend: menuExpend,
      categoryOpenID: getOpenCategoryID,
      mapRequirement: mapRequirement,
      mapRequirements: mapRequirements,
      hints: hints,
      quizPopup: quizPopup,
      getCategoriesPopupScrollPosition: getCategoriesPopupScrollPosition,
      getCategoryArticleClickedId: getCategoryArticleClickedId,
      filteredCategories: filteredCategories,
      filteredCategoriesProxyData: filteredCategories?.getData(),
      filteredLegendsProxyData: filteredLegends?.getData(),
      lampDataFiltered: lampDataFiltered,
      getLampSortedTooltipState: getLampSortedTooltipState,
      getLampSelectedId: getLampSelectedId,
      search: search,
      getSearchText: getSearchText,
      getMapStyledId: getMapStyledId,
      getMapStyledDarkId: getMapStyledDarkId,
      painterStart: painterStart,
      getStartPaintClickState: getStartPaintClickState,
      getPainterColor: getPainterColor,
      getPainterColorData: getPainterColorData,
      getPainterSize: getPainterSize,
      getPainterSizeData: getPainterSizeData,
      getColorPickerShowState: getColorPickerShowState,
      getRulerClickedState: getRulerClickedState,
      getEraserClickedState: getEraserClickedState,
      getPainterGeoJsonDataST:
        Object.keys(getPainterGeoJsonDataST).length > 0
          ? getPainterGeoJsonDataST
          : {
              type: "FeatureCollection",
              features: [],
            },
      getRulerGeoJsonDataST: getRulerGeoJsonDataST,
      getRulerSourceNameST: getRulerSourceNameST,
      getRulerSourceSecondNameST: getRulerSourceSecondNameST,
      getRulerPointsPosDataST: getRulerPointsPosDataST,
      getRulerLineStringST: getRulerLineStringST,
      getLampDataScreenShot: getLampDataScreenShot,
      getZoomDataST: getZoomDataST,
      getNewAngle: getNewAngle,
      getNewRotate: getNewRotate,
      getCompassCoordinates: getCompassCoordinates,
      getRulerPopupShowST: getRulerPopupShowST,
      getTimeLineZoomST: getTimeLineZoomST,
      getTimeLineZoomIndexST: getTimeLineZoomIndexST,
      getTimeLineMoveCountST: getTimeLineMoveCountST,
      getTimeLineCursorStatusST: getTimeLineCursorStatusST,
      getTimeLineEventDataST: getTimeLineEventDataST,
      getTimeLineMovePositionST: getTimeLineMovePositionST,
      getTimeLineMoveStatusST: getTimeLineMoveStatusST,
      getTimeLineScrollZoomST: getTimeLineScrollZoomST,
      getTimeTineZoomLevelST: getTimeTineZoomLevelST,
      timelineExtend: timelineExtend,
      getMapCenterCoordinatesST: getMapCenterCoordinatesST,
      getCategoriesSortingST: getCategoriesSortingST,
      getLampSortingST: getLampSortingST,
      selectedLesson: selectedLesson,
      articleData: articleData,
      menu: menu,
      getTimeLineMovieDataST: getTimeLineMovieDataST,
      getMapSelectedCluster,
      getTimeLineHeightST,getLampHideAndShowDataST,
      getTimeLineBlocksScrollPositionST, getLampModalState, getSearchCategoryId,getLampModalStateIndex, getTimeLineMidBlockParamsST,filteredLegends
    };

    editScreenDataAPI(
      parse(getScreenShotSendDataST).id,
      getTopicStaticTitleST,
      data
    );
    setScreenShotIntermediateDataST(stringify(data));
    setScreenShotSendDataST(stringify(data));

    setTimeout(() => {
      setScreenShotButtonClickST(!getScreenShotButtonClickST);
    }, 1000);

    overlay.current.style = "display:block";
    setTimeout(() => {
      overlay.current.style = "display:none";
    }, 500);

    setScreenShotState(false);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <button
        id="screenShot_icon"
        onClick={() => {
          isScreenShot && setScreenShotState(!screenShotState);
          !isScreenShot && handleOverlayBody();
        }}
        className="right-side__search-btn main__tab-btn screenshot-btn"
        type="button"
        style={{
          borderRadius: "10px",
          backgroundColor: "#fff",
          opacity: disable === "none" || disable ? 1 : 0.7,
        }}
        disabled={disable === "none" || disable ? false : true}
      >
        <span className="main__tab-icon icon-wrapper screenshot-icon">
          <img src={ScreenShotIcon} />
        </span>
      </button>

      {screenShotState && isScreenShot && (
        <div
          style={{
            backgroundColor: "#fff",
            padding: "20px 15px",
            borderRadius: "10px",
            marginTop: "20px",
            position: "absolute",
            width: "fit-content",
            display: "flex",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <div className="tooltip"></div>
          <button
            style={{
              backgroundColor: "#fff",
              border: "1px solid gray",
              width: "140px",
              fontSize: "12px",
              padding: "6px",
              marginRight: "4px",
              cursor: "pointer",
              borderRadius: "10px",
              fontWeight: 500,
            }}
            id="new_screenShot"
            onClick={() => {
              handleOverlayBody();
            }}
          >
            Ստեղծել նորը
          </button>
          <button
            style={{
              backgroundColor: "#fff",
              border: "1px solid gray",
              width: "140px",
              fontSize: "12px",
              padding: "6px",
              marginLeft: "4px",
              cursor: "pointer",
              borderRadius: "10px",
              fontWeight: 500,
            }}
            id="save_us_screenShot"
            onClick={() => {
              onScreenShotEdit();
            }}
          >
            Պահպանել
          </button>
        </div>
      )}
      <div ref={overlay} className={"body-overlay-shot"}></div>
    </div>
  );
};

const mapStateTopProps = (state) => ({
  topics: getTopicsData(state),
  timeLineItems: getTimeLineItemsData(state),
  menuExpend: getMenuExpendData(state),
  mapStateSingleData: getMapStateSingleData(state),
  getOpenCategoryID: getOpenCategoryID(state),
  mapRequirement: getMapRequirementData(state),
  mapRequirements: getMapRequirementsData(state),
  hints: getHintsData(state),
  quizPopup: getQuizPopupData(state),
  getCategoriesPopupScrollPosition: getCategoriesPopupScrollPosition(state),
  getCategoryArticleClickedId: getCategoryArticleClickedId(state),
  filteredCategories: getFilteredCategoriesData(state),
  getLampData: getLampData(state),
  getLampDataScreenShot: getLampDataScreenShot(state),
  getLampSortedTooltipState: getLampSortedTooltipState(state),
  getLampSelectedId: getLampSelectedId(state),
  search: getSearchData(state),
  getSearchText: getSearchText(state),
  getSearchCategoryId: getSearchCategoryId(state),
  getMapStyledId: getMapStyledId(state),
  getMapStyledDarkId: getMapStyledDarkId(state),
  painterStart: getPainterStartData(state),
  getStartPaintClickState: getStartPaintClickState(state),
  getPainterColor: getPainterColor(state),
  getPainterColorData: getPainterColorData(state),
  getPainterSize: getPainterSize(state),
  getPainterSizeData: getPainterSizeData(state),
  getColorPickerShowState: getColorPickerShowState(state),
  getRulerClickedState: getRulerClickedState(state),
  getEraserClickedState: getEraserClickedState(state),
  getPainterGeoJsonDataST: getPainterGeoJsonDataST(state),
  getRulerGeoJsonDataST: getRulerGeoJsonDataST(state),
  getRulerSourceName: getRulerSourceNameST(state),
  getRulerSourceSecondName: getRulerSourceSecondNameST(state),
  getRulerPointsPosDataST: getRulerPointsPosDataST(state),
  getRulerLineStringST: getRulerLineStringST(state),
  getLampHideAndShowDataST: getLampHideAndShowDataST(state),
  getZoomDataST: getZoomDataST(state),
  getNewAngle: getNewAngle(state),
  getNewRotate: getNewRotate(state),
  getCompassCoordinates: getCompassCoordinates(state),
  getRulerPopupShowST: getRulerPopupShowST(state),
  getTimeLineZoomST: getTimeLineZoomST(state),
  getTimeLineZoomIndexST: getTimeLineZoomIndexST(state),
  getTimeLineMoveCountST: getTimeLineMoveCountST(state),
  getTimeLineCursorStatusST: getTimeLineCursorStatusST(state),
  getTimeLineEventDataST: getTimeLineEventDataST(state),
  getTimeLineMovePositionST: getTimeLineMovePositionST(state),
  getTimeLineMoveStatusST: getTimeLineMoveStatusST(state),
  getTimeLineScrollZoomST: getTimeLineScrollZoomST(state),
  getTimeTineZoomLevelST: getTimeTineZoomLevelST(state),
  timelineExtend: getTimeLineExpendData(state),
  getMapCenterCoordinatesST: getMapCenterCoordinatesST(state),
  getScreenShotButtonClickST: getScreenShotButtonClickST(state),
  getCategoriesSortingST: getCategoriesSortingST(state),
  getLampSortingST: getLampSortingST(state),
  selectedLesson: getSelectedLessonId(state),
  articleData: getArticlesData(state),
  menu: getMenuData(state),
  getScreenShotIntermediateDataST: getScreenShotIntermediateDataST(state),
  getTimeLineMovieDataST: getTimeLineMovieDataST(state),
  getScreenShotSendDataST: getScreenShotSendDataST(state),
  getTopicStaticTitleST: getTopicStaticTitleST(state),
  getMapSelectedCluster: getMapSelectedCluster(state),
  getTimeLineHeightST: getTimeLineHeightST(state),
  getTimeLineBlocksScrollPositionST: getTimeLineBlocksScrollPositionST(state),
  getLampModalState: getLampModalState(state),
  getLampModalStateIndex: getLampModalStateIndex(state),
  getTimeLineMidBlockParamsST: getTimeLineMidBlockParamsST(state),
  filteredLegends: getFilteredLegendsData(state),
});

const mapDispatchToProps = {
  createScreenShotAPI,
  setScreenShotSendDataST,
  setScreenShotIntermediateDataST,
  setScreenShotButtonClickST,
  setScreenShotButtonStateST,
  editScreenDataAPI,
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(MapScreenShotButton);
