import {Component, PureComponent, useEffect} from "react";
import TopicArrow from "../UI/TopicArrow";
import {getArticleFromAPI} from "../../store/actions/articles";
import {useSelector} from "react-redux";

class ItemTemplate extends PureComponent {
  constructor(props) {
    super(props);
  }

  clickHandler = () => {
      this.props.item?.getArticle(this.props.item?.articleId)
  }
  render() {
    if (this.props.item?.className === "isGroup") {
      return (
        <div
        style={{
          display: 'none'
        }}
          id="group_content"
          className={`group-content ${this.props.item?.active ? "active" : ""}`}
        >
          <span id="left_topic_arrow_icon" className="left_topic_arrow_icon">
            <TopicArrow />
          </span>
          <p id="topic_title">{this.props.item.content}</p>
          <span id="right_topic_arrow_icon" className="right_topic_arrow_icon">
            <TopicArrow />
          </span>
        </div>
      );
    }
    else  if (this.props.item?.className === "isInner") {
      return (
        <div
          className="lesson__content-item"
          style={{
            backgroundColor: this.props.item.color ? this.props.item.color : "",
            opacity: this.props.item.show ? 1 : 0.5,
          }}
        >
          <span className="lesson__content-name">
            <img
              src={this.props.item?.catIcon}
              style={{
                width: 20,
                height: 20,
                marginRight: 10,
              }}
            />
            {this.props.item.content}
          </span>
        </div>
      );
    }
    // else  if (this.props.item?.className === "related-all") {
    //       return (
    //           <div
    //               className="lesson__content-item-related"
    //               style={{
    //                   borderColor: this.props.item?.category?.color  ? this.props.item?.category?.color  : "",
    //                   // opacity: this.props.item.show ? 1 : 0.5,
    //               }}
    //           >
    //           </div>
    //       );
    // }
    else  if (this.props.item?.className === "related-all-max" || this.props.item?.className === "related-all") {
          return (
                  <div
                      className="lesson__content-item-related-max"
                      onClick={this.clickHandler}
                  >
                      <div className={'lesson__content-item-related-max__overlay'}
                           style={{
                               backgroundColor: this.props.item?.category?.color  ? this.props.item?.category?.color  : "",
                           }}></div>
                     <div className={'lesson__content-item-related-max__content'}>
                          <span className={'lesson__content-item-related-max__content-border'}
                                style={{
                                    borderColor: this.props.item?.category?.color ? this.props.item?.category?.color : "",
                                }}></span>
                          <span className={'lesson__content-item-related-max__content-text'}>{this.props.item.content}</span>
                      </div>
                  </div>
          );
    }
    else  if (this.props.item?.className === "related-cluster") {
        return (
            <button
                id={'event-timeline'}
                type="button"
                className={`item-content timeline-cluster event-timeline`}
                style={{'--related-color':this.props.item?.color}}
                data-scale-start={this.props.item.scaleStart}
                data-scale-end={this.props.item.scaleEnd}
                data-open-middle-timeline = {this.props.item.openMiddleTimeline}
                data-bounds = {JSON.stringify(this.props.item.bounds)}
            >
                {this.props.item.count}
            </button>
        );
    }
    else if(this.props.item?.className === "related") {
      return (
        <button
          id={'event-timeline'}
          type="button"
          className={`item-content event-timeline ${this.props.item?.elementType}`}
          style={{'--related-color':this.props.item?.color}}
          data-bounds = {JSON.stringify(this.props.item.bounds)}
          data-is-lamp = {this.props.item?.elementType === 'Lamp'}
          data-article-id = {this.props.item?.articleId}
        >
          {this.props.item.content}
        </button>
      );
    }
    else{
      return (
        <button
          id={'lesson-item'}
          type="button"
          className={`item-content ${this.props.item?.elementType}`}
          style={{'--related-color':this.props.item?.color}}
          onClick={()=> {
              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
              const preview = urlParams.get('preview')
              window.location.href = `/map?${preview ? 'preview=true&' : ''}type=lesson&lessonID=${this.props.item.lessonId}`
          }}
        >
            {console.log(window.location.search,'window.location')}
          {this.props.item.content}
        </button>
      );
    }
  }
}

export default ItemTemplate;
