import axios from "axios";

export const loginUser = (url, body) => {
  return axios.post(url, body);
};

export const saveUserToken = (data) => {
  localStorage.setItem("accessToken", data.data[0].accessToken);
  if(data.rememberUser){
    localStorage.setItem("refreshToken", data.data[0].refreshToken);
  }
  return data;
};

export const navigateToAccountPage = (navigate, user) => {
  if(user?.countryId === null) {
    localStorage.setItem('signUpForm', JSON.stringify(user))
    // navigate("/account-info")
    window.location.href = "/account-info"
  } else if (window.innerWidth < 1024) {
    window.location.href = "/account-page"
    // navigate("/account-page");
  } else {
    // navigate("/account-boards");
    window.location.href = "/account-boards"
  }
};
