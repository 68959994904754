import React, { Component } from "react";
import Account from "../account";
import { Link, useNavigate } from "react-router-dom";
import "./style.css";
import Loader from "../../components/UI/Loader";
import ErrorAlert from "../../components/errorAlert";
import axios from "axios";
import { checkValidationErrors } from "../resetPass/form-validation";
import { getErrorMessage } from "../../helpers";
import { TextInput } from "../../components/UI/TextInput";
import { Button } from "../../components/UI/Button";
import {ResendCode} from "../../components/resendCode";

export const withNavigation = (Component: Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class ResetPassword extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      fields: {},
      errors: {},
      apiErrorMsg: "",
      showError: false,
    };
  }

  async componentDidMount() {
    const token = localStorage.getItem("accessToken");
    if (token) {
      window.location.href = "/account-boards";
    }
      let body = localStorage.getItem("forgotPassEmail") || localStorage.getItem("forgotPassPhoneNumber");
      if (JSON.parse(body) === null || JSON.parse(body) === undefined) {
          window.location.href = "/signup";
      }
  }

  contactSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    const errors = checkValidationErrors(this.state.fields);
    if (errors) {
      this.setState({ loading: false, errors });
    } else {
      this.setState({errors : []})
      this.resetPass();
    }
  }

  handleChange(field, e) {
    let fields = this.state.fields;
    fields[field] = e.target.value;
    this.setState({ fields });
  }

  resendCode = () => {
    const props = JSON.parse(localStorage.getItem("forgotPassPhoneNumber")) ?
        `${JSON.parse(localStorage.getItem("forgotPassPhoneNumberCode"))}/${JSON.parse(localStorage.getItem("forgotPassPhoneNumber"))}` :
        JSON.parse(localStorage.getItem("forgotPassEmail"))
    axios
        .get(
            `${process.env.REACT_APP_IDENTITY_URL}/api/User/ResetPassword/${props}`
        )
        .then(res => res)
  }

  resetPass = () => {
    const endpoint = JSON.parse(localStorage.getItem("forgotPassPhoneNumber")) ?
        '/api/User/ResetPasswordByPhoneNumber' :
        '/api/User/ResetPassword'
    const body = JSON.parse(localStorage.getItem("forgotPassPhoneNumber")) ?
        {
          phoneNumberCountryCode: JSON.parse(localStorage.getItem("forgotPassPhoneNumberCode")),
          phoneNumber: JSON.parse(localStorage.getItem("forgotPassPhoneNumber")),
          password: this.state.fields["password"],
          token: this.state.fields["token"],
        } :
        {
      email: JSON.parse(localStorage.getItem("forgotPassEmail")),
      password: this.state.fields["password"],
      token: this.state.fields["token"],
    };
      axios
          .post(
              `${process.env.REACT_APP_IDENTITY_URL}${endpoint}`,
              body
          )
          .catch(rej=>console.log(rej))
          .then((res) => {
            const errorState = {
              showError: true,
            };
            if (res?.data?.accepted) {
              this.props.navigate("/login");
              return;
            } else {
              errorState.apiErrorMsg = getErrorMessage(res.data.errorMessages);
              this.setState(errorState);
            }
          })
          .finally(()=>{
            this.setState({loading: false});
          })
  };
  render() {
    const { errors, fields, loading, showError, apiErrorMsg } = this.state;
    const { handleChange, contactSubmit, setState, resendCode } = this;

    return (
      <Account>
        <div className="account-content">
          <h2 className="reset-subtitle">Վերականգնեք ձեր գաղտնաբառը</h2>
          <span
            style={{
              color: "#fff",
              textAlign: "center",
              margin: "10px",
            }}
          >
            ԽՆԴՐՈՒՄ ԵՆՔ ՍՏՈՒԳԵԼ ՁԵՐ ԷԼԵԿՏՐՈՆԱՅԻՆ ՀԱՍՑԵՆ ՆՈՐ ԳԱՂՏՆԱԲԱՌԻ
            ԱԿՏԻՎԱՑՄԱՆ ՀԱՄԱՐ:
          </span>
          <form className="account-form" onSubmit={contactSubmit.bind(this)}>
            <TextInput
              hasError={apiErrorMsg === 'Անվավեր ծածկագիր' && apiErrorMsg || errors["token"]}
              placeholder="Կոդ *"
              type="number"
              onChange={handleChange.bind(this, "token")}
              value={fields["token"] || ""}
            />
            <TextInput
              hasError={errors["password"]}
              placeholder="Նոր գաղտնաբառը *"
              type="password"
              onChange={handleChange.bind(this, "password")}
              value={fields["password"] || ""}
            />
            <TextInput
              hasError={errors["cPassword"] ||
                  (apiErrorMsg === 'Գաղտնաբառն արդեն օգտագործվել է։ Խնդրում ենք ընտրել այլ գաղտնաբառ։' &&
                  apiErrorMsg)}
              placeholder="Հաստատե՛ք գաղտնաբառը *"
              type="password"
              onChange={handleChange.bind(this, "cPassword")}
              value={fields["cPassword"] || ""}
            />
            <Button
              text={"Վերականգնել գաղտնաբառը"}
              action={contactSubmit.bind(this)}
            />
          </form>
          <ResendCode
              action={resendCode}
          />
          {loading && <Loader />}

          {showError && apiErrorMsg !== 'Անվավեր ծածկագիր' &&
              apiErrorMsg !== 'Գաղտնաբառն արդեն օգտագործվել է։ Խնդրում ենք ընտրել այլ գաղտնաբառ։' &&
              (
            <ErrorAlert
              text={apiErrorMsg}
              close={() => setState({ showError: false })}
            />
          )}
          <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: '15px' }}>
            <span style={{ color: "#fff" }}>Արդեն ունե՞ք հաշիվ։</span>{" "}
            <Link className="reg" to="/login">
              Մուտք գործել
            </Link>
          </div>
        </div>
      </Account>
    );
  }
}

export default withNavigation(ResetPassword);
