import React, { Component } from "react";
import "./style.css";

class PrivacyPolicy extends Component {
    render() {
        return (
            <div className="policy-popup">
                <button className="policy-close" type="button" onClick={() => this.props.handlePolicy()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0,0H12" transform="translate(6 12)" fill="none" stroke="#292d32" stroke-width="1.5" strokeDasharray="0 0"/><path d="M0,12V0" transform="translate(12 6)" fill="none" stroke="#292d32" stroke-width="1.5" strokeDasharray="0 0"/><path d="M24,0V24H0V0Z" fill="none" opacity="0"/></svg>
                </button>
                <div className="policy-content">
                    <h2 className="policy-title">Գաղտնիության քաղաքականությունը</h2>
                    <p className="policy-text">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean vitae finibus odio. Mauris vitae augue vestibulum, euismod mi ac, auctor felis. Curabitur quam velit, posuere ut justo nec, pretium interdum ex. Fusce finibus semper purus vitae lobortis. Aliquam in lacus sed mauris dictum convallis. Vestibulum purus ligula, aliquet eget bibendum eget, blandit non enim. Sed pulvinar risus eu erat rhoncus, id ultricies nisl dignissim. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Mauris venenatis pulvinar nulla. Maecenas egestas ante in est efficitur, eu aliquet erat pulvinar. Etiam in nunc eu nibh semper viverra accumsan a justo. Nulla vel ante id metus congue dictum scelerisque consequat nibh.
                    </p>
                    <p className="policy-text">

                        Duis tincidunt imperdiet risus nec auctor. Nam suscipit laoreet aliquet. Nulla facilisi. Phasellus lobortis turpis lorem, et molestie neque pharetra quis. Proin arcu quam, placerat commodo risus sit amet, ultrices porttitor mauris. Aliquam consequat nibh nec libero tempus, sed consequat diam laoreet. Proin dignissim placerat nisl non tristique. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nulla consectetur quis libero a sollicitudin. Donec eu faucibus eros. Donec eleifend dolor felis, quis molestie ipsum porttitor vel. Duis iaculis magna augue, et cursus eros volutpat nec.
                    </p>
                    <p className="policy-text">
                        Etiam aliquam sem in mauris tristique, ac congue justo feugiat. Vivamus faucibus nisi sed bibendum consectetur. Donec posuere massa nec mauris aliquet, at iaculis ipsum venenatis. Suspendisse potenti. Sed elementum dignissim justo, vitae mattis enim pretium nec. Nunc pharetra non eros sed iaculis. Duis gravida velit at neque ullamcorper aliquam. Duis neque dolor, lobortis vitae convallis vel, mattis non magna. Sed sit amet est tortor. Fusce est orci, vehicula efficitur sollicitudin non, semper et odio. Donec vitae elit nec lorem sollicitudin venenatis. Pellentesque egestas turpis at urna fermentum, et efficitur orci finibus. Quisque lacinia vehicula tincidunt. Mauris hendrerit euismod lacus eu sollicitudin. Vivamus venenatis diam a dui finibus faucibus. Vivamus ultricies, urna ut rutrum accumsan, dolor lorem auctor libero, et efficitur mauris ante in nulla.
                    </p>
                    <p className="policy-text">
                        Etiam mollis, mi non fringilla viverra, elit ante volutpat lacus, sagittis condimentum magna diam at tellus. Integer dignissim imperdiet faucibus. Aliquam erat volutpat. Phasellus tellus nibh, vulputate ac suscipit id, tristique sit amet nisl. Vivamus laoreet urna sapien, ullamcorper sollicitudin erat laoreet sed. Cras sit amet mi mauris. Curabitur sit amet auctor est, eget ultricies elit. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nullam ut eleifend ipsum, ac hendrerit nisi. Interdum et malesuada fames ac ante ipsum primis in faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nunc tristique tincidunt nunc quis fermentum. Suspendisse porttitor massa non nisl lobortis euismod. Suspendisse dictum nisl at tortor maximus maximus. Aenean maximus dictum eros sed facilisis.
                    </p>
                    <p className="policy-text">
                        Morbi non sem semper, laoreet ante tristique, condimentum neque. Duis et metus neque. Morbi sagittis turpis vel tellus scelerisque, quis aliquam justo pharetra. In vitae elementum magna, ac tincidunt mi. Duis id leo at nunc hendrerit tempor. Ut auctor dui in eros commodo porta. Cras metus dolor, egestas in felis vel, sagittis vulputate massa. Proin diam neque, faucibus sed semper a, vestibulum id quam. Maecenas vitae elit sit amet nulla semper faucibus non at neque. Aenean at tristique lacus, non bibendum libero. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi ac eleifend neque, a sodales dui. Maecenas nec ipsum elementum, porttitor erat condimentum, luctus felis.
                    </p>
                    <p className="policy-text">
                        Morbi non sem semper, laoreet ante tristique, condimentum neque. Duis et metus neque. Morbi sagittis turpis vel tellus scelerisque, quis aliquam justo pharetra. In vitae elementum magna, ac tincidunt mi. Duis id leo at nunc hendrerit tempor. Ut auctor dui in eros commodo porta. Cras metus dolor, egestas in felis vel, sagittis vulputate massa. Proin diam neque, faucibus sed semper a, vestibulum id quam. Maecenas vitae elit sit amet nulla semper faucibus non at neque. Aenean at tristique lacus, non bibendum libero. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Morbi ac eleifend neque, a sodales dui. Maecenas nec ipsum elementum, porttitor erat condimentum, luctus felis.
                    </p>
                </div>
            </div>
        );
    }
}

export default PrivacyPolicy;
