import React, {useEffect} from "react"
import { Route, Routes } from "react-router"
import Map from "../pages/map"
import Home from "../pages/home"
import Login from "../pages/login"
import Signup from "../pages/signup"
import Forgot from "../pages/forgot"
import ResetPassword from "../pages/resetPass"
import EmailCheck from "../pages/emailCheck"
import AccountInfo from "../pages/accountInfo"
import AccountBoards from "../pages/accountBoards"
// import DynamicScreenshots from "../pages/dynamicScreenshots"
import Bookmarks from "../pages/bookmarks";
import AccountPage from "../pages/accountPage";
import About from "../pages/about";
import EmailSend from "../pages/emailSend";
import ConfirmAccount from "../pages/confirmAccount";
import ConfirmEmail from "../pages/confirmEmail";
import DynamicScreenshots from "../pages/dynamicScreenshots";
import {getUserFromAPI} from "../store/actions/user";
import {getUserData} from "../store/selectors";
import {connect} from "react-redux";
import {useLocation} from "react-router-dom";

const Navigation =  (props) => {
    const {pathname} = useLocation()
    const handleGetUser = async () =>{
        await props.getUserFromAPI();
    }
    useEffect(() => {
        if (!props.user && localStorage.getItem('accessToken')) {
            handleGetUser()
        }
    }, [props.user,pathname]);
    useEffect(()=>{
        window.addEventListener('message', (event) => {
            // Do not do anything unless the message was from
            // a domain we trust.
            if (event.origin !== process.env.REACT_APP_MAP_URL) return;
            // Create a local copy of the variable we were passed.
            var test_parameter = event.data;
            // Do something...
            localStorage.setItem('accessToken',test_parameter.accessToken)
            localStorage.setItem('admin','true')
            // localStorage.setItem('refreshToken',test_parameter.refreshToken)
            // Optionally reply to the message (Page A must also have
            // a 'message' event listener to receive this message).
            event.source.parent.postMessage(true, process.env.REACT_APP_MAP_URL);
        }, false);
        if(localStorage.getItem('admin') === 'true'){
            const preview = new URLSearchParams(window.location.search).get("preview");
            if(preview !== 'true') localStorage.clear();
        }
    },[])

    return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/map" element={<Map />} />
      <Route path="/about" element={<About />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Signup />} />
      <Route path="/forgot" element={<Forgot />} />
      <Route path="/resetPass" element={<ResetPassword />} />
      <Route path="/email-check" element={<EmailCheck />} />
      <Route path="/email-send" element={<EmailSend />} />
      <Route path="/account-info" element={<AccountInfo />} />
      <Route path="/account-boards" element={<AccountBoards />} />
      <Route path="/dynamic-screenshots" element={<DynamicScreenshots />} />
      <Route path="/bookmarks" element={<Bookmarks />} />
      <Route path="/account-page" element={<AccountPage />} />
      <Route path="/confirm-account" element={<ConfirmAccount />} />
      <Route path="/confirm-email" element={<ConfirmEmail />} />
    </Routes>
  );
};
const mapStateTopProps = (state) => ({
    user: getUserData(state),
})
const mapDispatchToProps = {
    getUserFromAPI,
}
export default connect(
    mapStateTopProps,
    mapDispatchToProps
)(Navigation);
