import { connect } from "react-redux";
import DynamicActionBtn from "../../DynamicScreenshotCard/DynamicActionBtn";
import "./style.css";
const DynamicTitle = (props) => {
  const { content, children } = props;

  return (
    <div className="card-body__dynamic-title dynamic-title">
      <h4>{content}</h4>
      {children}
    </div>
  );
};
export default connect(null, null)(DynamicTitle);
