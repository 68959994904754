import React, { Component } from "react";
import Account from "./../account";
import { Link } from "react-router-dom";
import ErrorAlert from "../../components/errorAlert";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/UI/Loader";
import { checkValidationErrors } from "./form-validation";
import { TextInput } from "../../components/UI/TextInput";
import { Button } from "../../components/UI/Button";
import { connect } from "react-redux";
import { getUserFromAPI, setUser } from "../../store/actions/user";
import { loginUser, navigateToAccountPage, saveUserToken } from "./utils";
import {loginByPhoneURI, loginURI, userInfoURI} from "../../shared/mockData";
import {getUser, isUserLoggedIn} from "../../store/selectors";
import axios from "axios";
import PhoneInput from "react-phone-input-2";

export const withNavigation = (Component: Component) => {
  return (props) => <Component {...props} navigate={useNavigate()} />;
};

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      fields: {
        selectedLoginType: "Email",
        phoneCode: '374'
      },
      errors: {},
      apiErrorMsg: "",
      showError: false,
      rememberUser: false,
    };
  }

  async componentDidMount() {
    if (this.props.isLoggedIn) {
      window.location.href = "/account-boards";
    }
  }

  contactSubmit(e) {
    this.setState({ loading: true });
    e.preventDefault();
    const errors = checkValidationErrors(this.state.fields);
    if (errors) {
      this.setState({ loading: false, errors });
    } else {
      this.signIn();
    }
  }

  handleChange(field, e, el) {
    let fields = this.state.fields;
    if(field === 'phoneCode') {
      fields["phoneCode"] = el.dialCode;
    } else {
      fields[field] = e.target.value
    }
    this.setState({ fields });
  }

  signIn = () => {
    const url = this.state.fields.selectedLoginType === 'Phone' ?
        loginByPhoneURI :
        loginURI
    const body = this.state.fields.selectedLoginType === 'Phone' ?
        {
          phoneNumberCountryCode: this.state.fields.phoneCode,
          phoneNumber: this.state.fields.phoneNumber,
          password: this.state.fields.password
        } :
        {
      email: this.state.fields["email"],
      password: this.state.fields["password"],
    };
    const errorState = {
      loading: false,
      showError: true,
    };
    loginUser(url, body)
      .then((res) => {
        if (res.data.accepted === false) {
          return Promise.reject(res.data.errorMessages);
        }
        res.data.rememberUser = this.state.rememberUser;
        return res.data;
      })
      .then(saveUserToken)
      .then(() =>
          axios.get(userInfoURI)
          .then((res) => {
            this.setState({
              user: res.data.data[0].userInfo,
            })
          }))
      .then(() => {
        navigateToAccountPage(this.props.navigate, this.state.user)})
      .catch(() => {
        errorState.apiErrorMsg = "Սխալ մուտքանուն կամ գաղտնաբառ";
        this.setState(errorState);
      });
  };
  _setRememberUser = (e) => {
    this.setState({ rememberUser: e.target.checked });
  };
  render() {
    const { errors, fields, loading, showError, apiErrorMsg } = this.state;
    const { handleChange, contactSubmit, setState, user } = this;

    return (
      <Account>
        <div className="account-content">
          <h2 className="account-subtitle">
            Ճանաչել զիմաստութիւն եւ զխրատ, իմանալ զբանս հանճարոյ
          </h2>
          <div
              className={'switchButtonContainer'}>
            <Button
                value="Email"
                action={handleChange.bind(this, "selectedLoginType")}
                className={fields["selectedLoginType"] === 'Email' ? 'switchButtonActive' : 'switchButton'}
                text='Էլ. հասցեով'
            />
            <Button
                value='Phone'
                action={handleChange.bind(this, "selectedLoginType")}
                className={fields["selectedLoginType"] === 'Phone' ? 'switchButtonActive' : 'switchButton'}
                text='Հեռ. համարով'
            />
          </div>
          <form className="account-form" onSubmit={contactSubmit.bind(this)}>
            {fields['selectedLoginType'] === "Phone" ?
                <div
                    style={{width: "450px",
                      alignItems: 'flex-end',
                      display: 'flex',
                      marginTop: '-10px',
                      flexDirection: 'column'}}>
                  <PhoneInput
                      inputProps={{
                        disabled: true,
                        style: { pointerEvents: 'none'}
                      }}
                      countryCodeEditable={false}
                      containerClass='phoneContainer'
                      inputClass={errors["phoneNumber"] ?
                          "phoneInputError" :
                          "phoneInput"}
                      buttonClass='flagButton'
                      country={'am'}
                      value=""
                      onChange={handleChange.bind(this, "phoneCode")}
                  />
                  {errors["phoneNumber"] && <span className={'error-text'}>* {errors["phoneNumber"]}</span>}
                  <input
                      onInput={(e) => e.target.value =
                          fields.phoneCode == '374' ? e.target.value.slice(0, 8) :
                              e.target.value.slice(0, 15)}
                      ref={this.phoneNumberInputRef}
                      value={fields['phoneNumber']}
                      type="number"
                      placeholder={fields['phoneCode'] == '374' ?
                      "Մուտքագրեք հեռախոսահամարն առանց 0-ի *" :
                      "Հեռախոսահամար *"}
                      onChange={handleChange.bind(this, "phoneNumber")}
                      className={errors["phoneNumber"] ? 'phoneNumberInputError':'phoneNumberInput'}/>
                </div> :
              <TextInput
                hasError={errors["email"]}
                placeholder="Էլ. հասցե *"
                onChange={handleChange.bind (this, "email")}
                value={fields["email"] || ""}
            />}
            <TextInput
              hasError={errors["password"]}
              placeholder="Գաղտնաբառ *"
              type={"password"}
              onChange={handleChange.bind(this, "password")}
              value={fields["password"] || ""}
            />
            <Button text={"մուտք"} action={contactSubmit.bind(this)} />
          </form>
          {loading && <Loader />}

          {showError && (
            <ErrorAlert
              text={`${apiErrorMsg} *`}
              close={() => setState({ showError: false })}
            />
          )}
          <div className="remember-user__box">
            <input
              id="remember-user"
              type="checkbox"
              onInput={this._setRememberUser}
            />
            <label htmlFor="remember-user">Հիշել</label>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              marginBottom: "30px",
            }}
          >
            <span style={{ color: "#fff" }}>Մոռացե՞լ եք գաղտնաբառը։</span>{" "}
            <Link className="reg" to="/forgot">
              Վերականգնել
            </Link>
          </div>
          <Link className="reg" to="/signup">
            գրանցվել
          </Link>
        </div>
      </Account>
    );
  }
}

const mapStateTopProps = (state) => ({
  isLoggedIn: isUserLoggedIn(state),
  user: getUser(state)
});
const mapDispatchToProps = {
  getUserFromAPI,
  setUser,
};

export default connect(
  mapStateTopProps,
  mapDispatchToProps
)(withNavigation(Login));
