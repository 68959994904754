import { SET_LESSON_ID, SET_NEXT_LESSON, SET_PREV_LESSON } from '../constants'

export const setNextID = (id) => ({
    type: SET_NEXT_LESSON,
    payload: id,
})

export const setPrevID = (id) => ({
    type: SET_PREV_LESSON,
    payload: id,
})

export const setSelectedLesson = (id) => ({
    type: SET_LESSON_ID,
    payload: id,
})

export const setNextLessonID = (item) => (dispatch, getState) => {
    const state = getState()
    console.log(state.timeLineItems,'state.timeLineItems')
    const id = state.timeLineItems.data.find((x) => x.id === item).nextLessonID
    dispatch(setNextID(id))
}
export const setPrevLessonID = (item) => (dispatch, getState) => {
    const state = getState()
    let id = state.timeLineItems.data.find((x) => x.id === item).prevLessonID
    dispatch(setPrevID(id))
}
