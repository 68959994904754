import {
    LOADING_ARTICLES_ALL,
    SET_ARTICLES_ALL,
    SET_SELECTED_ARTICLE_ALL,
    SET_ARTICLE_IS_LIKED
} from '../constants'

const initialState = {
    data: [],
    selectedArticleDataAll: {},
    aricleIsLiked: null,
    isLoading: false,
}

export const articlesAllReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOADING_ARTICLES_ALL: {
            return { ...state, isLoading: true }
        }
        case SET_ARTICLES_ALL:
            return {
                ...state,
                isLoading: false,
                data: action.payload,
            }
        case SET_SELECTED_ARTICLE_ALL:
            return {
                ...state,
                selectedArticleDataAll: action.payload,
            }
        case SET_ARTICLE_IS_LIKED:
            return {
                ...state,
                aricleIsLiked: action.payload,
            }
        default:
            return state
    }
}
