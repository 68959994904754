import React, { useEffect } from "react";
import MiniGallery from "../miniGallery";
import AnimationGallery from "../animationGallery";
import Plus from "../UI/Plus";
import Minus from "../UI/Minus";
import CloseDarkModal from "../UI/CloseDarkModal";
import AudioPlayer from "../audioPlayer";
import { getCategoriesData } from "../../store/selectors";
import { connect } from "react-redux";
import {
  closeArticlePopup,
  collapsArticlePopup,
} from "../../store/actions/articles";
import galleryImgIcon from "../../assets/imgs/gallery-img-icon.svg";
import galleryVideoIcon from "../../assets/imgs/gallery-video-icon.svg";
import gallery360Icon from "../../assets/imgs/gallery-360-icon.svg";
import { setPainterStart } from "../../store/actions/painterStart";
import { setSearch } from "../../store/actions/search";
import { setMapStyle } from "../../store/actions/mapStyle";
import ArticlePopup from "../articlePopup";
import { useRef } from "react";
import { useState } from "react";
import {DangerouslySetInnerHTML} from "./ArticleReadMoreComponents/ArticleReadMoreComponents";

const ArticleItem = (props) => {
  const {
    article,
    categories,
    collapsArticlePopup,
    closeArticlePopup,
    toggleGallery,
    toggleShowArticlePopup,
    stopPinAnimation
  } = props;

  const scrollZone = useRef();

  useEffect(() => {
    if (article?.articleData?.gallery?.[0]?.scrollY) {
      const scrollEl = document.getElementById(`hero__descr_${article.id}`);

      scrollEl.scrollTop = article.articleData.gallery?.[0]?.scrollY;
    }
  }, [article]);

  const galleryContainItems = {
    video: false,
    image: false,
    audio: false,
    R360: false,
  };
  article.articleData?.gallery?.forEach((value, index, self) => {
    if (self.findIndex((el) => el.type === value.type) === index) {
      switch (value.type) {
        case 0:
          galleryContainItems.image = true;
          break;
        case 1:
          galleryContainItems.image = true;
          break;
        case 2:
          galleryContainItems.R360 = true;
          break;
        case 3:
          galleryContainItems.video = true;
          break;
        case 4:
          galleryContainItems.audio = true;
          break;
      }
    }
  });

  const gallery =
    !!article?.articleData?.content?.[0]?.featuredImage
      ? [
          ...[
            {
              mediaId: null,
              path: article?.articleData?.content?.[0].featuredImage,
            },
          ],
          ...article?.articleData?.gallery,
        ]
      : article?.articleData?.gallery;
  useEffect(() => {
    props.setPainterStart(false);
    props.setSearch(false);
  }, []);

  const onChangePlayer = (type, state) => {
    if (!article.articleData.gallery?.[0]["saveData"]) {
      article.articleData.gallery[0].saveData = {
        isPlay: null,
        currentTime: null,
        volumeToggle: null,
        volume: null,
      };
    }
    switch (type) {
      case "playing":
        article.articleData.gallery[0].saveData["playing"] = state;
        break;
      case "currentTime":
        article.articleData.gallery[0].saveData["currentTime"] = state;
        break;
      case "volumeToggle":
        article.articleData.gallery[0].saveData["volumeToggle"] = state;
        break;
      case "volume":
        article.articleData.gallery[0].saveData["volume"] = state;
        break;
      default:
        break;
    }
  };

  return (
    <div
      key={article.id}
      className={`hero ${!article?.show ? "minimized" : ""}`}
    >
      <div className="hero__info-wrapper">
        <div className="hero__header">
          <h2 className="hero__title">
            {!article?.show
              ? article?.articleData?.content?.[0].shortTitle
              : article?.articleData?.content?.[0].mainTitle}
          </h2>
          <div className="hero__btns">
            <button
              className="hero__collapse"
              type="button"
              onClick={() => {
                collapsArticlePopup(article.id);

                setTimeout(() => {
                  const scrollEl = document.getElementById(
                    `hero__descr_${article.id}`
                  );

                  scrollEl.scrollTop = article.articleData.gallery?.[0]?.scrollY;
                }, 0);
              }}
            >
              {!article.show ? <Plus /> : <Minus />}
            </button>
            <button
              className="hero__close"
              type="button"
              onClick={() => {
                closeArticlePopup(article.id);
                stopPinAnimation();
              }}
            >
              <CloseDarkModal />
            </button>
          </div>
        </div>
        <div className="hero__media-wrapper popup-image-section">
          {gallery?.length > 0 ? (
            <AnimationGallery
              src={article?.articleData?.featuredImage}
              feautured={article?.articleData?.content?.[0]?.featuredImage}
              gallery={gallery}
              openLightBox={(id) => toggleGallery(id)}
              isMinimized={!article.show}
            />
          ) : (
            <div className="hero__media_animation__box">
              <button className="hero__media" id="lightgallery" type="button">
                <img
                  src={require("../../assets/imgs/image_placeholder.png")}
                  alt="image_placeholder"
                />
              </button>
            </div>
          )}

          {/*{article.show && gallery.length > 0 ? (*/}
          {/*    <AnimationGallery*/}
          {/*        gallery={gallery}*/}
          {/*        openLightBox={(id) => toggleGallery(id)}*/}
          {/*    />*/}
          {/*) : (*/}
          {/*    <div className="hero__media_animation__box">*/}
          {/*        <button*/}
          {/*            className="hero__media"*/}
          {/*            id="lightgallery"*/}
          {/*            type="button"*/}
          {/*        >*/}
          {/*            {gallery.length > 0 ? (*/}
          {/*                <img*/}
          {/*                    src={gallery[0].path}*/}
          {/*                    alt="image_placeholder"*/}
          {/*                />*/}
          {/*            ) : (*/}
          {/*                <img*/}
          {/*                    src={require('../../assets/imgs/image_placeholder.png')}*/}
          {/*                    alt="image_placeholder"*/}
          {/*                />*/}
          {/*            )}*/}
          {/*        </button>*/}
          {/*        <div className="hero__media__liner-gradien"></div>*/}
          {/*    </div>*/}
          {/*)}*/}
          {
            <div className={"hero__media_icons"}>
              {galleryContainItems.image && (
                <span>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={galleryImgIcon}
                    alt="galleryImgIcon"
                  />
                </span>
              )}
              {galleryContainItems.video && (
                <span>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={galleryVideoIcon}
                    alt="galleryVideoIcon"
                  />
                </span>
              )}
              {galleryContainItems.R360 && (
                <span>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={gallery360Icon}
                    alt="galleryVideoIcon"
                  />
                </span>
              )}
            </div>
          }
          {/*<p className="hero__category">*/}
          {/*    {*/}
          {/*        categories.find(*/}
          {/*            (x) =>*/}
          {/*                x.id ===*/}
          {/*                article.articleData?.category[0].categoryId*/}
          {/*        )?.title*/}
          {/*    }*/}
          {/*</p>*/}
          {/*<div className="hero__media-items"></div>*/}
          {/*{article?.articleData?.content[0].externalUrl !== null ? (*/}
          {/*    <a*/}
          {/*        className="hero__more"*/}
          {/*        target={'_blank'}*/}
          {/*        href={article?.articleData?.content[0].externalUrl}*/}
          {/*        rel="noreferrer"*/}
          {/*    >*/}
          {/*        ԱՎԵԼԻՆ*/}
          {/*    </a>*/}
          {/*) : null}*/}
          <button
            className="hero__more"
            onClick={() => {
              toggleShowArticlePopup(article.id);
            }}
          >
            ԱՎԵԼԻՆ
          </button>
        </div>
        <div className="hero__info">
          <div className="hero__text padding-right-hero">
            <div
              className="hero__descr"
              id={`hero__descr_${article.id}`}
              ref={scrollZone}
              onScroll={(e) => {
                article.articleData.gallery[0].scrollY = e.target.scrollTop;
              }}
            >
              <DangerouslySetInnerHTML content={article?.articleData?.content?.[0]?.introText}/>
            </div>
          </div>
        </div>
      </div>
      {article?.articleData?.gallery?.find((x) => x.type === 4) && (
        <AudioPlayer
          onChangePlayer={onChangePlayer}
          saveData={article?.articleData?.gallery?.[0]?.saveData}
        />
      )}
    </div>
  );
};
const mapStateTopProps = (state) => ({
  categories: getCategoriesData(state),
});

const mapDispatchToProps = {
  collapsArticlePopup,
  closeArticlePopup,
  setPainterStart,
  setSearch,
};

export default connect(mapStateTopProps, mapDispatchToProps)(ArticleItem);
